window.addEvent('domready', function(){

	if ((Auth.Request.controller !== 'clients' && Auth.Request.controller !== 'prospects') || Auth.Request.action !== 'view')
		return;

	var PriceQuotes = $('PriceQuotes');
	var LoadQuotes = $('LoadQuotes');
	var MoreQuotes = $('MoreQuotes');
	var isMobile = $$('.clients.view.mobile')[0];

	if (!LoadQuotes || !MoreQuotes || !PriceQuotes)
	{
		return;
	}

	var PlanSpecial = Auth.Plan.special;

	if (!PlanSpecial)
	{
		var request_link_project = new Request({
			method: 'post',
			onSuccess: function(response)
			{
				if (response.length == 0)
				{
					location.reload();
					return;
				}
				$('GenericModal').getElement('.modal-content').set('html', response);
				jQuery('#GenericModal').modal('show');
			}
		});
	}

	var is_sat_billing_activated = parseInt(Auth.CompanySettings.sat_billing || 0);

	var QuotesTableView = new TableView(PriceQuotes, {
		url: '/' + Auth.Request.controller + '/quotes/' + Auth.Request.pass[0],
		limit: 10,
		no_more_rows: $('NoMoreQuotes'),
		next_page: MoreQuotes,
		onRow: function(row, template)
		{
			var quote  = row.PriceQuote;
			var orders = row.ClientOrder;
			var is_project_price_quote = quote.is_project ? '<span class="glyphicon glyphicon-folder-open" aria-hidden="true"></span>' : '';

			template.setProperty('data-price_quote_id', quote.id).store('price_quote', row);
			template.getElement('.mail-template')
				.set('href', '/api/price_quotes/view/' + quote.hash)
				.set('html', is_project_price_quote + quote.title)
				.addClass('modal-launcher');

			var total = parseFloat(quote.total).format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS
			});
			var regex_zeroes_replace = new RegExp('\.0{' + COMPANY_DECIMALS + '}$');

			template.getElement('.price-quote-total').set('html', (total + '').replace(regex_zeroes_replace, ''));
			template.getElement('.mail-views').set('html', quote.views).set('title', quote.viewer_ip);
			template.getElement('.mail-dispatched .glyphicon').addClass(row.Mail.sent ? 'glyphicon-check' : 'glyphicon-menu-close');

			if (row.Mail.error)
			{
				var ErrorSpan = template.getElement('.tooltip-error');
				if (ErrorSpan !== null)
				{
					ErrorSpan.set('data-original-title', row.Mail.message);
				}
			}

			template.getElement('.mail-dispatched .text').set('html', quote.sent_times > 1 ? quote.sent_times : '');

			if (quote.email)
			{
				var mail_sent_date = Date.parse(row.Mail.sent_date).setUserOffset();
				var naturalDate = (new Date(mail_sent_date)).naturalDate();
				var shortTime = (new Date(mail_sent_date)).shortTime();

				template.getElement('.price-quote-sent-date')
					.set('html', naturalDate)
					.set('data-original-title', shortTime);
			}
			else
			{
				template.getElement('.price-quote-sent-date').set('html', '—');
			}

			if (!PlanSpecial)
			{
				template.getElement('.link-project').addEvent('click', function(event){
					event.stop();
					event.stopPropagation();
					request_link_project.send({
						url: '/price_quotes/link_project/' + quote.id + '/' + CLIENT.id
					});
				});
			}

			if (row.Project.name)
			{
				template.getElement('.link-project').set('text', row.Project.name);
			}
			if (is_sat_billing_activated && row.Invoice.id && window.InvoiceModal)
			{
				var invoice_link = new Element('span', {
					class: 'label label-success label-outline pointer',
					text: Locale.get('Invoice.invoice') + ' #' + row.Invoice.folio
				}).inject(template.getElement('.price-quote-invoice'));

				invoice_link.addEvent('click', function(){
					var ViewInvoiceModal = new InvoiceModal(row.Invoice.id, {
						indicators: [invoice_link],
						destroyOnHide: true
					});
				});
			}
			if (orders.length > 0)
			{
				template.getElement('.price-quote-payments').addEvent('click', function(event){

					if (this.hasClass('popover-loaded'))
					{
						return;
					}

					var paymentTableTemplate = [
						'<div class="table-responsive"><table class="price-quote-popover-table">',
						'<tbody>{charges}</tbody></table></div>'
					].join('');

					var paymentTemplate = [
						'<tr><td class="payment-credit-card"><span class="label label-credit-card',
						' enable-popover-tooltip" data-toggle="tooltip" title="{card_owner}">{credit_card}</span>',
						'<td class="payment-card-brand"><span><strong>{card_brand}</strong>',
						'</td></span></td><td class="payment-date"><span class="enable-popover-tooltip" ',
						'data-toggle="tooltip" title="{charge_time}">{charge_date}</span></td>',
						'<td class="payment-status"><span class="label {status_class}">{charge_status}</span>&nbsp;',
						'<a href="https://admin.conekta.com/orders/{conekta_url}" target="_blank">',
						'<span class="glyphicon glyphicon-log-in"></span></a></td></tr>'
					].join('');

					var statusClasses = {
						paid: 'label-success',
						pending: 'label-warning',
						declined: 'label-danger'
					}

					var popover_payments = '';

					orders.each(function(order, i){
						var	charges     = JSON.decode(order.charges);
						var	client      = JSON.decode(order.customer_info);

						charges.each(function(charge, c){
							var order_modified = Date.parse(order.modified).setUserOffset();
							popover_payments += paymentTemplate.substitute({
								card_owner: charge.payment_method.name,
								credit_card: '**** **** **** ' + charge.payment_method.last4,
								charge_status: Locale.get('Payments.' + charge.status),
								status_class: statusClasses[charge.status],
								card_brand: Locale.get('Payments.' + charge.payment_method.brand),
								charge_date: (new Date(order_modified)).naturalDate(),
								charge_time: (new Date(order_modified)).naturalTime(),
								conekta_url: order.order_id + '/charges/' + charge.id,
							});
						});
					});

					popover_payments = paymentTableTemplate.substitute({
						charges: popover_payments
					});

					jQuery(this).popover({
						title: Locale.get('Payments.payments'),
						content: popover_payments,
						placement: 'top',
						container: 'body',
						html: true
					})
					.on('shown.bs.popover', function(){
						jQuery('.enable-popover-tooltip').tooltip();
					})
					.popover('show');

					this.addClass('popover-loaded');
				});
			}
			else
			{
				template.getElements('.price-quote-payments').addClass('hidden');
			}

			var human_status = template.getElement('.human-status');
			human_status.addClass(StatusSale.labels[row.PriceQuoteStatus.status_sale_id] + ' modal-launcher');
			human_status.set('text', row.PriceQuoteStatus.name);

			new PriceQuoteModal(template.getElements('.modal-launcher'), {
				price_quote_id: quote.id
			});

			return template;
		}
	});

	var refresh_tooltips = function()
	{
		jQuery('.enable-tooltip').tooltip();
	}

	jQuery('#LoadQuotes').on('shown.bs.tab', function(){
		QuotesTableView.reset();
		setTimeout(refresh_tooltips, 1000);
	});

	MoreQuotes.addEvent('click', function(){
		QuotesTableView.next();
		setTimeout(refresh_tooltips, 1000);
	});

	if (document.URL.indexOf('#ClientQuotes') > -1)
	{
		var tab_quotes = jQuery('#Tracing a[href="#Quotes"]');
		tab_quotes.tab('show');
		QuotesTableView.reset();
		window.scrollTo(tab_quotes.offset().top, 0);
	}

	if (PlanSpecial && !isMobile)
	{
		var load_quotes = jQuery('#LoadQuotes');
		load_quotes.tab('show');
	}
});
