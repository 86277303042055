window.addEvent('domready', function(){

	if (!(Auth.User) ||
		(Auth.Request.controller !== 'clients' && Auth.Request.controller !== 'prospects') ||
		(Auth.Request.action !== 'view' && Auth.Request.action !== 'view_mobile')
	)
		return;

	var SendMailModal = $$('.send-mail-modal');
	var mail_modal_launcher = function(SendMailTrigger)
	{
		SendMailTrigger.addEvent('click', function(event){
			event.stop();
			var ModalSendMail = $('ModalSendMail');
			var modal_loder = new Element('p', {'class': 'text-center'});
			var loader_default = new Element('span', {'class': 'loader loader-default'});
			var cancel_button = new Element('p', {'class': 'text-right'});
			new Element('button', {
				'class': 'pull-right btn btn-default',
				'data-dismiss': 'modal',
				'aria-hidden': true,
				'html': 'Cancelar'
			}).inject(cancel_button);
			loader_default.inject(modal_loder);
			modal_loder.inject(ModalSendMail.getElement('.modal-body').set('html', ''));
			cancel_button.inject(ModalSendMail.getElement('.modal-footer').set('html', ''));
			ModalSendMail.getElement('.modal-footer').removeClass('alert-success').removeClass('alert-warning');
			jQuery('#ModalSendMail').modal('show');
			new Request({
				method: 'post',
				url: Auth.Request.here.replace(/\/view\//, '\/send_mail\/'),
				onSuccess: function(response)
				{
					ModalSendMail.getElement('.modal-content').set('html', response);
					add_events('Ajax');
				}
			}).send();
		});
	}
	SendMailModal.each(mail_modal_launcher);

	var smtp = null;
	var ModalSendMail = $('ModalSendMail');

	jQuery('#ModalSendMail').on('hidden.bs.modal', function(e) {
		if (smtp)
		{
			smtp.destroy();
			smtp = null;
		}

		if ($('AddCc'))
		{
			$('AddCc').removeEvents();
		}

		$('MailSendMailForm').removeEvents();
		$('MailMailTemplateId').removeEvents();
		$$('.reload_button').removeEvents();
		if (typeof tinyMCE !== 'undefined')
		{
			tinyMCE.remove();
		}

	});

	var add_events = function(mode)
	{
		var MailUserSmtp       = $('MailUserSmtp');
		var SendMail           = $('SendMail' + mode);
		var AddCc              = $('AddCc');
		var MailCc             = $('MailCc');
		var MailSendMailForm   = $('MailSendMailForm');
		var MailSubject        = $('MailSubject');
		var MailMailTemplateId = $('MailMailTemplateId');
		var EmailInputs        = $$('.validate-email');

		if (MailUserSmtp)
		{
			smtp = new SwitchSMTP(MailUserSmtp, {
				smtp: Auth.Smtp,
				sender: $('MailSender'),
				label: $('SmtpSource')
			});
		}

		EmailInputs.each(function(input, i){
			input.addEvent('change', function(){
				this.set('value', this.get('value').replace(/\s/g,''));
			});
		});

		var validation_fields = function()
		{
			var send_flag        = true;
			var mail_template_id = parseInt($('MailMailTemplateId').get('value'));
			var mail_message     = $('MailMessage');
			var mce_panel        = $$('.tox-tinymce');
			var mail_subject     = $('MailSubject');
			var EmailInputs      = $$('.validate-email');

			if ($('MailMessage_ifr') && mail_template_id === 0)
			{
				mce_panel.getParent().removeClass('has-error');
				if ($('MailMessage').get('value') === '')
				{
					send_flag = false;
					mail_message.focus();
					mce_panel.getParent().addClass('has-error');
					tinyMCE.activeEditor.focus();
				}
			}
			if (mail_subject && mail_template_id === 0)
			{
				mail_subject.getParent().removeClass('has-error');
				if (mail_subject.get('value') === '')
				{
					send_flag = false;
					mail_subject.focus();
					mail_subject.getParent().addClass('has-error');
				}
			}

			EmailInputs.each(function(input, i){
				var fieldValue = input.get('value');

				input.getParent().removeClass('has-error');
				if (fieldValue !== '' && !isEmailValid(fieldValue))
				{
					send_flag = false;
					input.getParent().addClass('has-error');
					input.focus();
				}
			});

			return send_flag;
		}

		var add_to_cc = function(event)
		{
			var mail = this.get('html');
			var cc_value = MailCc.get('value');
			mail = cc_value !== '' ? ',' + mail : mail;
			MailCc.set('value', cc_value + mail);
		}

		if (SendMail)
		{
			var cc = SendMail.getElements('.cc');
			var cc_mails = SendMail.getElements('.cc-mails');
			if (AddCc && cc)
			{
				AddCc.addEvent('click', function(){
					AddCc.getParent('.form-group').remove();
					cc.removeClass('hidden');
					cc.each(function(event) {
						event.getElement('.email-copy').focus();
					});
					if (cc_mails)
					{
						cc_mails.removeClass('hidden');
						cc_mails.getElements('span').forEach(function(mail){
							mail.addEvent('click', add_to_cc);
						});
					}
				});
			}
		}

		if (ModalSendMail)
		{
			ModalSendMail.getElements('.input-switch').each(function(element, i){
				new Element('label', {'for': element.get('id')}).inject(element, 'after');
				element.addClass('input-switch-enable');
			});
		}

		if (MailSendMailForm)
		{
			(function(){
				var SendMailAction = new CompanyActions();
				var Action = SendMailAction.shouldBlockAction();

				if (!Action.isPermitted)
				{
					var new_footer = new Element('div.modal-footer.alert-' + Action.response.type);
					var action_message = new Element('p', {'class': 'text-left'});

					new Element('span', {
						'text': Action.response.message,
						'style': 'display: inline-block; margin-top: 6px; max-width: 90%;'
					}).inject(action_message);

					new Element('a.close_button', {
						'text': 'Cerrar',
						'href': '#',
						'class': 'pull-right btn btn-warning',
						'data-dismiss': 'modal',
						'aria-hidden':'true',
						'style': 'max-width: 10%'
					}).inject(action_message);

					MailSendMailForm.getElements('input,select,textarea').set('disabled', true);
					MailSendMailForm.getElement('.modal-footer').dispose();
					action_message.inject(new_footer);
					new_footer.inject(MailSendMailForm, 'bottom');
				}
			})();

			MailSendMailForm.addEvent('submit', function(event){
				event.stop();
				if (typeof tinyMCE !== 'undefined')
				{
					tinyMCE.triggerSave();
				}
				if (validation_fields())
				{
					MailSendMailForm.getElements('.loader').removeClass('hide');

					var form_data =  MailSendMailForm.toQueryString();

					MailSendMailForm.getElement('input[type="submit"]').set('disabled', true);

					new Request.JSON({
						method: 'post',
						data: form_data,
						url: MailSendMailForm.get('action'),
						onSuccess: function(response)
						{
							response = response.alert;

							if (MailUserSmtp)
								MailUserSmtp.removeEvents();

							var MailSendMailForm = $('MailSendMailForm');
							var original_footer = MailSendMailForm.getElement('.modal-footer').dispose();

							var new_footer = new Element('div.modal-footer.alert-' + response.type);
							new_footer.inject(MailSendMailForm, 'bottom');

							var message_success = new Element('p', {'class': 'text-left'});

							new Element('span', {
								'text': response.message,
								'style': 'position: absolute; margin-top: 6px;'
							}).inject(message_success);

							if (response.type === 'success')
							{
								new Element('a', {
									'text': 'Cerrar',
									'class': 'pull-right btn btn-success',
									'data-dismiss': 'modal',
									'aria-hidden':'true'
								}).inject(message_success);
								MailSendMailForm.getElements('input').set('disabled', true);
								MailSendMailForm.getElements('select').set('disabled', true);
								if (tinymce.activeEditor)
									tinymce.activeEditor.getBody().style.background = '#eeeeee';
								original_footer = null;
							}
							else
							{
								var reload_button = new Element('a.reload_button', {
									'text': 'Reintentar',
									'href': '#',
									'class': 'pull-right btn btn-warning'
								});
								reload_button.inject(message_success);
								reload_button.addEvent('click', function(){
									original_footer.replaces(new_footer);

									MailSendMailForm.getElement('input[type="submit"]').removeAttribute('disabled');
									original_footer = null;

									if (MailUserSmtp)
									{
										MailUserSmtp.removeEvents();
										smtp = new SwitchSMTP(MailUserSmtp, {
											smtp: Auth.Smtp,
											sender: $('MailSender'),
											label: $('SmtpSource')
										});
									}

									this.removeEvents();
								});
							}
							message_success.inject(MailSendMailForm.getElement('.modal-footer').set('html', ''));
						}
					}).send();
				}
			})

			tinyMCE.init({
				cache_suffix: '?v=5.0.3',
				mode: 'textareas',
				editor_selector: 'tinymce',
				mobile: {
					theme: 'silver',
					plugins: '',
					menubar: false,
					toolbar1: ''
				},
				menubar: false,
				toolbar_items_size: 'small',
				plugins: 'link anchor fullpage paste',
				contextmenu: false,
				paste_as_text: true,
				width: '100%',
				height: 200,
				convert_urls: false,
				statusbar: false,
				toolbar1: 'bold italic underline strikethrough fontcolor fontselect fontsizeselect forecolor link',
				language: Locale.getCurrent().name === 'es-ES' ? 'es_MX' : 'en_US'
			});
		}

		function preview_before_send(template_id)
		{
			var client_id = Auth.Request.here.match(/(view|edit|send_mail)\/(\d+)/g)[0].split('/')[1];
			new Request.JSON({
				method: 'post',
				url: '/mail_templates/preview_before_send/' + client_id + '/' + template_id + '.json',
				onSuccess: function(response)
				{
					tinyMCE.activeEditor.setContent(response.render.mail_content);
					MailSubject.set('value', response.render.mail_subject);
				}
			}).send();
		}

		if (MailMailTemplateId)
		{
			new DynamicSearch({
				target: MailMailTemplateId,
				multiple: !!MailMailTemplateId.getProperty('multiple'),
				search: {
					enable: MailMailTemplateId.getElements('option').length > 10,
					min_chars: 2
				},
				style: 'width: 100%'
			});

			MailMailTemplateId.addEvent('change', function(){
				var mail_template_id = parseInt(MailMailTemplateId.get('value'));
				if (mail_template_id > 0)
					preview_before_send(mail_template_id);
			});
		}
	}

	add_events('');
});
