/**
 * Returns a response wheather you can or cannot do stuff.
 */
var CompanyActions = new Class({
	initialize: function()
	{
		var STATUS_NORMAL     = 1;
		var STATUS_STANDBY    = 2;
		var STATUS_BLOCKED    = 3;

		var COMPANY_STATUS_ID = Auth.Company.company_status_id;

		var alert = {
			success: 'success',
			warning: 'warning',
			danger: 'danger'
		};

		var action = {
			'isPermitted': true,
			'response': {
				'message': '',
				'type': ''
			}
		};

		this.shouldBlockAction = function()
		{
			if (COMPANY_STATUS_ID == STATUS_STANDBY)
			{
				action.isPermitted      = false;
				action.response.message = Locale.get('Default.companyActionStandBy');
				action.response.type    = alert.warning;
			}
			if (COMPANY_STATUS_ID == STATUS_BLOCKED)
			{
				action.isPermitted      = false;
				action.response.message = Locale.get('Default.companyActionBlocked');
				action.response.type    = alert.warning;
			}

			return action;
		}
	}
});
