var PriceQuoteModal = new Class({
	elements: [],
	Implements: Options,
	listeners: [],
	modal: null,
	modal_jquery: null,
	options: {
		price_quote_id: 0,
	},
	price_quote: null,
	status_tag: null,
	switch_smtp: null,
	request_view: null,
	initialize: function(elements, options)
	{
		if (!elements || elements.length <= 0 || parseInt(options.price_quote_id) <= 0)
		{
			return false;
		}

		elements.each(function(element){
			if (element.match('span') && element.hasClass('human-status'))
			{
				this.status_tag = element;
			}
		}.bind(this));

		if (!this.status_tag)
		{
			return false;
		}

		this.elements = elements;
		this.setOptions(options);

		this.elements.setStyle('cursor', 'pointer').addEvent('click', this.show.bind(this));
		this.request_view = new Request.JSON({
			method: 'post',
			url: '/price_quotes/view/' + this.options.price_quote_id,
			onSuccess: this.loadElements.bind(this),
		});

		this.modal_jquery = jQuery('#GenericModal');
		this.modal = $('GenericModal');
	},
	show: function(event)
	{
		event.stop();
		this.modal_jquery.modal('show');

		this.request_view.send();

		this.modal_jquery.on('hide.bs.modal', function() {
			this.reset();
		}.bind(this));
	},
	loadElements: function(price_quote)
	{
		if (price_quote.error)
		{
			location.reload();
			return;
		}
		this.price_quote = price_quote.price_quote;
		// Load html modal header
		this.modal.getElement('.modal-title').set(
			'text', Locale.get('Price.priceQuote') + ' ' + this.price_quote.PriceQuote.title
		);

		// Load html modal body
		var info = [
			'<div class="row">',
				'<label class="col-sm-4 control-label">{field}</label>',
				'<div class="col-sm-8">',
					'<p class="form-control-static">{value}</p>',
				'</div>',
			'</div>',
		].join('');
		var info_special = [
			'<div class="row">',
				'<label class="col-sm-4 control-label">{field}</label>',
				'<div class="col-sm-8">',
					'<p class="form-control-static">{value}',
					'<a special-toggle="{toggle_id}" class="pull-right special-toggle" ',
					'href="javascript:void(0);">{toggle_text}</a>',
					'</p>',
					'<div id="{toggle_id}" class="hide">{toggle_content}</div>',
				'</div>',
			'</div>',
		].join('');

		var status_options = [];
		this.price_quote.PriceQuoteStatus.each(function(status){
			var option = [
				'<option',
				'data-status-sale-id="' + status['data-status-sale-id'] + '"',
				'value="' + status['value'] + '">',
				status['name'],
				'</option>'
			];
			status_options.push(option.join(' '));
		});

		var users_options = [];
		for (var user_index in this.price_quote.UsersList)
		{
			var user = this.price_quote.UsersList[user_index];
			var option = '<option ' + 'value="' + user['value'] + '">' + user['name'] + '</option>';

			if (+this.price_quote.PriceQuote.user_assigned_id === +user_index)
			{
				option = '<option ' + 'value="' + user['value'] + '" selected="selected">' + user['name'] + '</option>';
			}

			users_options.push(option);
		}

		var price_quote_status_id_select = [
			'<select name="data[price_quote_status_id]" id="PriceQuoteStatus" class="form-control input-sm">',
				status_options.join(''),
			'</select>'
		].join('');
		var user_assigned_select = [
			'<select name="data[user_assigned_id]" id="UserAssignedSelect" class="form-control input-sm">',
				users_options.join(''),
			'</select>'
		].join('');

		var price_quote_link = [
			'<a href="/api/price_quotes/view/' + this.price_quote.PriceQuote.hash + '" target="_blank">',
				this.price_quote.PriceQuote.title,
			'</a>'
		].join('');

		var invoice_link = '';
		if (this.price_quote.Invoice.id)
		{
			invoice_link = info.substitute({
				field: Locale.get('Invoice.invoice'),
				value: [
					'<a href="/api/invoice/' + this.price_quote.Invoice.hash + '"',
					' title="' + this.price_quote.Invoice.created + '" target="_blank">',
						Locale.get('Invoice.view'),
					'</a>',
				].join('')
			});
		}

		var price_quote_created = Date.parse(this.price_quote.PriceQuote.created).setUserOffset();
		var content = [
			'<div class="form-horizontal">',
				info.substitute({field: Locale.get('Price.priceQuote'), value: price_quote_link}),
				info_special.substitute({
					field: Locale.get('Default.userAssigned'),
					value: this.price_quote.User.name,
					toggle_id: 'UserAssigned',
					toggle_text: Locale.get('Default.change'),
					toggle_content: user_assigned_select
				}),
				info.substitute({
					field: Locale.get('Default.createdDate'),
					value: [
						(new Date(price_quote_created)).naturalDate(),
						(new Date(price_quote_created)).shortTime()
					].join(' — ')
				}),
				invoice_link,
				info.substitute({field: Locale.get('Default.status'), value: price_quote_status_id_select}),
			'</div>'
		].join('');

		this.modal.getElement('.modal-body').empty().set('html', content);
		this.modal.getElement('.modal-body #PriceQuoteStatus').set('value', this.price_quote.PriceQuote.price_quote_status_id);

		// Load html modal footer
		var CancelButton = new Element('button', {
			'class': 'btn btn-default',
			'data-dismiss': 'modal',
			'aria-hidden': true,
			'html': Locale.get('Default.close')
		});

		var SaveButton = new Element('button', {
			'class': 'btn btn-primary',
			'html': Locale.get('Default.save')
		});

		var DuplicateOption = new Element('a', {
			'href': [
				'/price_quotes/create',
				'/client:', this.price_quote.PriceQuote.client_id,
				'/project:', this.price_quote.PriceQuote.project_id,
				'/price_quote:', this.price_quote.PriceQuote.id
			].join(''),
			'title': Locale.get('Price.editBeforeResend'),
			'html': '<span class="glyphicon glyphicon-copy-duplicate"></span> ' + Locale.get('Price.duplicatePriceQuote')
		});

		var LaunchDropdownMenu = new Element('button', {
			'class': 'btn btn-default dropdown-toggle ',
			'data-toggle': 'dropdown',
			'aria-expanded': 'false',
			'html': Locale.get('Default.options') + '&nbsp;<span class="caret"></span>'

		});
		var DropdownMenu = new Element('ul', {
			'class': 'dropdown-menu pull-left text-left ',
			'style': 'margin-left: 15px;',
			'role': 'menu',
		});

		var send = !this.price_quote.PriceQuoteMails.subject && !this.price_quote.PriceQuoteMails.message;
		var ResendOption = new Element('a', {
			'href': 'javascript:void(0);',
			'style': 'cursor:pointer',
			'html': '<span class="glyphicon glyphicon-envelope"></span> ' +
			(send ? Locale.get('Price.sendPriceQuote') : Locale.get('Price.resendPriceQuote'))
		});
		var ForcePDFOption = new Element('a', {
			'href': '/price_quotes/force_pdf/' + this.price_quote.PriceQuote.hash,
			'style': 'cursor:pointer',
			'html': '<span class="glyphicon glyphicon-file-refresh"></span> ' + Locale.get('Price.regeneratePDF')
		});
		var WhatsappMessage = new Element('a', {
			'href': null,
			'style': 'cursor:pointer',
			'html': '<span class="glyphicon glyphicon-send"></span> ' + Locale.get('PriceQuote.sendViaWhatsapp')
		});
		var DownloadPDF = new Element('a', {
			'href': '/api/price_quotes/download_pdf/' + this.price_quote.PriceQuote.hash + '/:file_name:' + this.price_quote.PriceQuote.hash + '.pdf',
			'style': 'cursor:pointer',
			'html': '<span class="glyphicon glyphicon-cloud-download"></span> ' + Locale.get('Price.downloadPDF')
		});

		WhatsappMessage.addEvent('click', function(){
			var whatsapp_templates_layout = [];
			var WHATSAPP_VARIABLES = Object.merge(USER_VARIABLES, CLIENT);

			if (WHATSAPP_TEMPLATES && WHATSAPP_TEMPLATES.length != 0)
			{
				var templates = [];

				WHATSAPP_TEMPLATES.each(function(template){
					templates.push('<option value="' + JSON.decode(template.content.substitute(WHATSAPP_VARIABLES)) + '" >' + template.name + '</option>');
				});

				whatsapp_templates_layout = [
					'<div class="form-group"><label>',
					Locale.get('Default.whatsappTemplates'),
					'</label><select id="TemplateSelect" class="form-control dynamic-search">',
					templates.join(),
					'</select><br></div>',
				];
			}

			var whatsapp_modal = new BootstrapModal({
				title: Locale.get('PriceQuote.sendViaWhatsapp'),
				primaryButton: {
					html: '<span class="glyphicon glyphicon-send"></span> ' + Locale.get('Default.continue'),
				},
				secondaryButton: {
					class: 'btn btn-default',
					'data-dismiss': 'modal',
					html: Locale.get('Default.close')
				},
				previousButton: null,
				onPrimary: function(event)
				{
					event.stop();

					var MobileView = $$('.clients.view.mobile')[0];
					var whatsapp = 'https://api.whatsapp.com/send?phone={code}{phone}&text={message}%0A%0A{price_quote_url}';
					var WhatsappMessageForm = $('WhatsappMessageForm');
					var price_quote_url = window.location.origin + '/api/price_quotes/view/';

					if (MobileView)
					{
						whatsapp = 'whatsapp://send?phone={code}{phone}&text={message}%0A%0A{price_quote_url}';
					}

					window.open(
						whatsapp.substitute({
							code: (+WhatsappMessageForm.getElement('input.area-code').value.replace(/\D/g,'')) || 52,
							phone: +WhatsappMessageForm.getElement('input.phone-number').value.replace(/\D/g,''),
							price_quote_url: price_quote_url + this.price_quote.PriceQuote.hash,
							message: encodeURI(WhatsappMessageForm.getElement('textarea').value)
						}),
						'_blank',
						'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes'
					);

					whatsapp_modal.dispose();
				}.bind(this),
				body: [
					'<style>',
					'.area-code { width: 10% !important; display: inline-block; margin-right: 5px;}',
					'.phone-number { width: 30% !important; display: inline-block;}',
					'</style>',
					'<div id="WhatsappMessageForm">',
					whatsapp_templates_layout.join(''),
					'<div class="form-group">',
					'<input class="form-control area-code" maxlength="3" size="3" placeholder="+52" value="+52">',
					'<input class="form-control phone-number" maxlength="20" value="',
					(typeof CLIENT != undefined ? CLIENT.cellphone ? CLIENT.cellphone : CLIENT.phone : ''),
					'" size="10" placeholder="',
					Locale.get('PriceQuote.phoneNumber') + '">',
					'</div>',
					'<div class="form-group">',
					'<textarea class="form-control" type="text" placeholder="',
					Locale.get('PriceQuote.messageOptional') + '"></textarea>',
					'</div>',
					'</div>'
				].join('')
			});

			var TemplateSelect = whatsapp_modal.body.getElement('#TemplateSelect');
			if (TemplateSelect)
			{
				var whatsapp_message   = JSON.decode(Auth.CompanySettings.whatsapp_message || '');
				whatsapp_modal.body.getElement('textarea').value =  whatsapp_message.price_quote_message || '';

				TemplateSelect.addEvent('change', function(){
					whatsapp_modal.body.getElement('textarea').value = this.value.substitute(CLIENT) || '';
				});
			}

			whatsapp_modal.show();
		}.bind(this));

		DropdownMenu.grab((new Element('li')).grab(DuplicateOption));
		DropdownMenu.grab((new Element('li')).grab(ResendOption));
		DropdownMenu.grab((new Element('li')).grab(WhatsappMessage));
		DropdownMenu.grab((new Element('li')).grab(ForcePDFOption));
		DropdownMenu.grab((new Element('li')).grab(DownloadPDF));

		var sat_billing_activated = parseInt(Auth.CompanySettings.sat_billing || 0);

		if (this.price_quote.Invoice.id === null && sat_billing_activated && window.InvoicingStory)
		{
			var InvoicingButton = new Element('a', {
				href: 'javascript:void(0);',
				style: 'cursor:pointer',
				html: '<span class="glyphicon glyphicon-notes"></span> ' + Locale.get('Invoice.generate')
			});
			InvoicingButton.addEvent('click', function(){
				var invoicing_modal = new InvoicingStory(this.price_quote.PriceQuote.id, {
					modal: this.modal_jquery,
					fromModal: true
				});
				invoicing_modal.start();
			}.bind(this));

			DropdownMenu.grab(new Element('li', {class: 'divider'}));
			DropdownMenu.grab((new Element('li')).grab(InvoicingButton));
		}
		else if (sat_billing_activated && window.InvoicingStory)
		{
			var InvoiceButton = new Element('a', {
				href: 'javascript:void(0);',
				style: 'cursor:pointer',
				html: '<span class="glyphicon glyphicon-notes"></span> ' + Locale.get('Invoice.view')
			});
			InvoiceButton.addEvent('click', function(){
				this.modal_jquery.modal('hide');
				new InvoiceModal(this.price_quote.Invoice.id);
			}.bind(this));

			DropdownMenu.grab(new Element('li', {class: 'divider'}));
			DropdownMenu.grab((new Element('li')).grab(InvoiceButton));
		}

		var standard_footer = new Element('div', {'class': 'row'}).grab(
			new Element('div', {'class': 'col-xs-4 col-sm-6 text-left'}).grab(LaunchDropdownMenu).grab(DropdownMenu)
		).grab(
			new Element('div', {'class': 'col-xs-8 col-sm-6 text-right'}).grab(CancelButton).grab(SaveButton)
		);
		this.modal.getElement('.modal-footer').empty().grab(standard_footer);

		SaveButton.addEvent('click', this.update.bind(this));
		ResendOption.addEvent('click', this.prepareResend.bind(this));

		this.listeners.push(SaveButton);
		this.listeners.push(ResendOption);

		this.modal.getElements('.special-toggle').each(function(special_toggle){
			special_toggle.addEvent('click', function(){
				$(special_toggle.getProperty('special-toggle')).removeClass('hide hidden');
				special_toggle.getParent('p').destroy();
			});
		});
	},
	update: function()
	{
		var footer = this.modal.getElement('.modal-footer');
		var PriceQuoteStatus = this.modal.getElement('#PriceQuoteStatus');
		var UserAssigned = this.modal.getElement('#UserAssignedSelect');
		var footer_botton = [
			'<button class="pull-right btn btn-sm btn-{context}" data-dismiss="modal" aria-hidden="true">',
				'Cerrar',
			'</button><p class="text-left ">{message}</p>',
		].join('');

		new Request.JSON({
			method: 'post',
			url: '/price_quotes/update/' + this.options.price_quote_id,
			data: [
				'data[price_quote_status_id]=' + PriceQuoteStatus.get('value'),
				'data[user_assigned_id]=' + UserAssigned.get('value')
			].join('&'),
			onSuccess: function(response)
			{
				var context = 'warning';
				if (response.error)
				{
					location.reload();
					return;
				}
				else if (response.success)
				{
					context = 'success';
					this.status_tag.removeProperty('class');
					this.status_tag.addClass('label');
					this.status_tag.addClass(StatusSale.labels[response.new_status_sale_id]);
					this.status_tag.set('text', response.new_status);
				}
				footer.addClass('alert-' + context);
				footer.set('html', footer_botton.substitute({
					context: context,
					message: response.message
				}));
			}.bind(this),
			onFailure: function()
			{
				footer.addClass('alert-danger');
				footer.set('html', footer_botton.substitute({
					context: 'danger',
					message: Locale.get('Default.connection_error')
				}));
			},
			onComplete: function()
			{
				PriceQuoteStatus.set('disabled', true);
			}
		}).send();

	},
	prepareResend: function()
	{
		this.reset();

		this.modal.getElement('.modal-title').set('text', Locale.get('Price.resendPriceQuote'));

		var row_required = [
			'<div class="form-group">',
				'<label class="col-sm-2 control-label">',
					'<span class="asterisk-required-field">* </span>',
					'{field}',
				'</label>',
				'<div class="col-sm-10">{input}</div>',
			'</div>',
		].join('');
		var row = [
			'<div class="form-group">',
				'<label class="col-sm-2 control-label">',
					'{field}',
				'</label>',
				'<div class="col-sm-10">{input}</div>',
			'</div>',
		].join('');

		var quote_subject = (this.price_quote.PriceQuoteMails.subject || this.price_quote.PriceQuote.title)
		var quote_message = (
			this.price_quote.PriceQuoteMails.message ||
			this.price_quote.Settings.price_quotes_default_mail_content
		);

		var content = [
			'<form class="resend-price-quote">',
				'<div class="form-horizontal">' +
					row_required.substitute({
						field: Locale.get('Price.sendTo'),
						input: '<input name="data[Mail][email]" type="text" value="' + (this.price_quote.Client.email || '') + '" >'
					}),
					row_required.substitute({
						field: Locale.get('Price.subject'),
						input: '<input name="data[PriceQuoteMails][subject]" type="text" value="' + quote_subject + '" >'
					}),
					row_required.substitute({
						field: Locale.get('Price.message'),
						input: '<textarea name="data[PriceQuoteMails][message]" class="tinymce">' + quote_message + '</textarea>'
					}),
					row.substitute({
						field: Locale.get('Price.cc'),
						input: '<input name="data[PriceQuote][cc]" placeholder="example@mail.com, example2@mail.com">'
					}),
				'</div>',
			'</form>'
		].join('');

		this.modal.getElement('.modal-body').set('html', content);
		this.modal.getElements('.modal-body input, .modal-body textarea').addClass('form-control input-sm');

		tinyMCE.init({
			cache_suffix: '?v=5.0.3',
			mode: 'textareas',
			editor_selector: 'tinymce',
			mobile: {
				theme: 'silver',
				plugins: '',
				menubar: false,
				toolbar1: ''
			},
			menubar: false,
			toolbar_items_size: 'small',
			plugins: 'link anchor paste',
			contextmenu: false,
			paste_as_text: true,
			autoresize_min_height: 100,
			autoresize_max_height: 800,
			width: '100%',
			height: 250,
			convert_urls: false,
			statusbar: false,
			toolbar1: 'bold italic underline strikethrough link ',
			language: Locale.getCurrent().name === 'es-ES' ? 'es_MX' : 'en_US'
		});

		var footer = this.modal.getElement('.modal-footer');

		var send = !this.price_quote.PriceQuoteMails.subject && !this.price_quote.PriceQuoteMails.message;
		var ResendButton = new Element('button', {
			'class': 'btn btn-primary',
			'style': 'margin-left: 5px;',
			'html': send ? Locale.get('Price.sendPriceQuote') : Locale.get('Price.resendPriceQuote')
		});

		ResendButton.inject(footer);
		ResendButton.addEvent('click', this.resend.bind(this));
		this.listeners.push(ResendButton);

		var loader = new Element('span', {
			'class': 'loader loader-default hide',
			'style': 'position: absolute; right: 240px;'
		})
		loader.inject(ResendButton, 'before');

		var sender_mail = new Element('input', {
			'type': 'hidden',
			'name': 'data[PriceQuote][sender_email]',
			'value': Auth.Smtp && Auth.Smtp.username ? Auth.Smtp.username : Auth.User.username
		});

		sender_name = new Element('input', {
			'type': 'hidden',
			'name': 'data[PriceQuote][sender_name]',
			'value': Auth.User.name
		});

		var resend_price_quote = this.modal.getElement('.resend-price-quote');
		sender_name.inject(resend_price_quote);
		sender_mail.inject(resend_price_quote);

		if (Auth.Smtp)
		{
			var smtp_selector = new Element('input', {
				'type': 'checkbox',
				'id': 'UserSmtp',
				'class': 'form-control input-switch input-switch-sm pull-left',
				'name': 'data[PriceQuote][user_smtp]',
				'checked': Auth.Smtp ? true : false,
			});

			smtp_selector.inject(footer);
			new Element('label', {'for': smtp_selector.get('id')}).inject(smtp_selector, 'after');
			smtp_selector.addClass('input-switch-enable');

			this.modal.getElement('.modal-footer').appendHTML([
				'<small class="pull-left">',
					'<label class="text-muted" style="font-weight: normal; float: left; margin-top:5px">',
						Locale.get('Price.sendFrom') + ': ',
						'<span class="smtp-source" id="SmtpSource">',
							Auth.Smtp.host ? Auth.Smtp.host : mail.incrementa.host,
						'</span>',
					'</label>',
				'</small>'
			].join(''));

			this.switch_smtp = new SwitchSMTP(smtp_selector, {
				smtp: Auth.Smtp,
				sender: sender_mail,
				label: $('SmtpSource')
			});
		}
	},
	resend: function()
	{
		var send = true;
		var form = this.modal.getElement('.resend-price-quote');
		var mce_panel = this.modal.getElement('.tox-tinymce');
		var loader = this.modal.getElement('.loader');
		var subject = this.modal.getElement('input[name="data[PriceQuoteMails][subject]"]');
		var email = this.modal.getElement('input[name="data[Mail][email]"]');
		var message = this.modal.getElement('textarea[name="data[PriceQuoteMails][message]"]');

		loader.removeClass('hide');

		form.getElements('.has-error').removeClass('has-error');

		if (subject.get('value') === '')
		{
			send = false;
			subject.getParent().addClass('has-error');
		}

		if (email.get('value') === '' || !email.get('value').test(/\S+@\S+\.\S+/))
		{
			send = false;
			email.getParent().addClass('has-error');
		}

		mce_panel.setStyle('border-color', '#ccc');

		if (tinyMCE.activeEditor.getContent() === '')
		{
			send = false;
			mce_panel.setStyle('border-color', 'red');
			message.focus();
			tinyMCE.activeEditor.focus();
		}
		else
		{
			message.set('value', tinyMCE.activeEditor.getContent());
		}

		if (send)
		{
			var footer = this.modal.getElement('.modal-footer');
			var footer_botton = [
				'<button class="pull-right btn btn-sm btn-{context}" data-dismiss="modal" aria-hidden="true">',
					'Cerrar',
				'</button><p class="text-left ">{message}</p>',
			].join('');

			footer.getElements('button.btn').set('disabled', 'disabled');

			var form_data = form.toQueryString();
			var UserSmtp = $('UserSmtp')
			if (UserSmtp)
			{
				form_data = form_data + '&data[PriceQuote][user_smtp]=' + UserSmtp.get('checked');
			}

			new Request.JSON({
				method: 'post',
				url: [
					'/price_quotes/resend/price_quote:',
					this.options.price_quote_id,
					'/client:',
					this.price_quote.PriceQuote.client_id
				].join(''),
				data: form_data,
				onSuccess: function(response)
				{
					if (response.error)
					{
						location.reload();
						return;
					}
					footer.addClass('alert-' + response.result.class);
					footer.set('html', footer_botton.substitute({
						context: 'success',
						message: response.result.message
					}));
				},
				onFailure: function()
				{
					footer.addClass('alert-danger');
					footer.set('html', footer_botton.substitute({
						context: 'danger',
						message: Locale.get('Default.connection_error')
					}));
				},
				onComplete: function()
				{
					email.set('disabled', true);
					subject.set('disabled', true);
					tinyMCE.activeEditor.getBody().setAttribute('contenteditable', false);
					tinyMCE.activeEditor.getBody().setAttribute('readonly', true);
					tinyMCE.activeEditor.getBody().setAttribute('style', 'background-color: #eeeeee!important;');
				}
			}).send();
		}
		else
		{
			loader.addClass('hide');
		}
	},
	reset: function()
	{
		// reset html modal header
		this.modal.getElement('.modal-title').set('text', '');

		// reset html modal body
		this.modal.getElement('.modal-body').empty().grab(
			(new Element('p', {'class': 'text-center'})).grab(new Element('span', {'class': 'loader loader-default'}))
		);

		// reset html moodal footer
		this.modal.getElement('.modal-footer').removeProperty('class').empty().grab(
			new Element('button', {
				'class': 'btn btn-default',
				'data-dismiss': 'modal',
				'text': Locale.get('Default.close')
			})
		).addClass('modal-footer');

		this.listeners.each(function(element, key){
			element.removeEvents();
		});

		if (tinyMCE.activeEditor)
		{
			tinyMCE.activeEditor.destroy();
		}

		if (this.switch_smtp)
		{
			this.switch_smtp.destroy();
			this.switch_smtp = null;
		}
	}
});
