var Filters = new Class({
	Implements: Options,
	bitmask: 0,
	user_id: false,
	campaign_id: false,
	options: {
		User: null,
		Campaign: null,
		ActionCall: null,
		ActionMeeting: null,
		ActionEmail: null,
		ActionActivity: null,
		ActionWhatsApp: null,
		ActionVideoCall: null,
		ActionGroup: null
	},

	initialize: function(options)
	{
		this.setOptions(options);

		var filters = JSON.decode(Cookie.read('dashboard_filters' + Auth.User.id));
		if (filters)
		{
			if (this.options.User)
				this.options.User.set('value', filters.user_id);

			if (this.options.Campaign)
				this.options.Campaign.set('value', filters.campaign_id);

			if ((filters.bitmask & 1) === 0 && this.options.ActionCall)
				this.options.ActionCall.removeClass('active');

			if ((filters.bitmask & 2) === 0 && this.options.ActionMeeting)
				this.options.ActionMeeting.removeClass('active');

			if ((filters.bitmask & 4) === 0 && this.options.ActionEmail)
				this.options.ActionEmail.removeClass('active');

			if ((filters.bitmask & 8) === 0 && this.options.ActionActivity)
				this.options.ActionActivity.removeClass('active');

			if ((filters.bitmask & 16) === 0 && this.options.ActionWhatsApp)
				this.options.ActionWhatsApp.removeClass('active');

			if ((filters.bitmask & 32) === 0 && this.options.ActionVideoCall)
				this.options.ActionVideoCall.removeClass('active');

			if ((filters.bitmask & 64) === 0 && this.options.ActionGroup)
				this.options.ActionGroup.removeClass('active');

			this.bitmask = filters.bitmask;
			this.user_id = filters.user_id;
			this.campaign_id = filters.campaign_id;
		}
		else
		{
			this.update();
		}
	},

	match: function(data)
	{
		if (this.user_id && this.user_id !== data.user_assigned_id)
		{
			if (!GROUPED_USERS[this.user_id] || !GROUPED_USERS[this.user_id][data.user_assigned_id])
				return false;
		}

		if (this.campaign_id === -1 && data.campaign_id > 0)
			return false;

		if (this.campaign_id > 0 && data.campaign_id !== this.campaign_id)
			return false;

		if (data.action_type_id && (this.bitmask & Math.pow(2, data.action_type_id - 1)) === 0)
			return false;

		return true;
	},

	update: function()
	{
		this.bitmask = 0;

		if (this.options.ActionCall && this.options.ActionCall.hasClass('active'))
		{
			this.bitmask = this.bitmask | 1;
		}
		if (this.options.ActionMeeting && this.options.ActionMeeting.hasClass('active'))
		{
			this.bitmask = this.bitmask | 2;
		}
		if (this.options.ActionEmail && this.options.ActionEmail.hasClass('active'))
		{
			this.bitmask = this.bitmask | 4;
		}
		if (this.options.ActionActivity && this.options.ActionActivity.hasClass('active'))
		{
			this.bitmask = this.bitmask | 8;
		}
		if (this.options.ActionWhatsApp && this.options.ActionWhatsApp.hasClass('active'))
		{
			this.bitmask = this.bitmask | 16;
		}
		if (this.options.ActionVideoCall && this.options.ActionVideoCall.hasClass('active'))
		{
			this.bitmask = this.bitmask | 32;
		}
		if (this.options.ActionGroup && this.options.ActionGroup.hasClass('active'))
		{
			this.bitmask = this.bitmask | 64;
		}

		this.user_id = this.options.User ? this.options.User.get('value').toInt() : false;
		this.campaign_id = this.options.Campaign ? this.options.Campaign.get('value').toInt() : false;

		if (isNaN(this.campaign_id))
			this.campaign_id = false;
		if (isNaN(this.user_id))
			this.user_id = false;

		Cookie.write('dashboard_filters' + Auth.User.id, JSON.encode({
			bitmask: this.bitmask,
			user_id: this.user_id,
			campaign_id: this.campaign_id
		}), {duration: 1});

		return this;
	}
});
