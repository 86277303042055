Date.implement('setUserOffset', function(){
	this.setSeconds(this.getSeconds() + Auth.User.timezone_offset);

	return this;
});

Date.implement('naturalTime', function(){
	return this.setUserOffset().format(Locale.get('Date.naturalTime'));
});

Date.implement('naturalDate', function(){
	return this.setUserOffset().format(Locale.get('Date.naturalDate'));
});

Date.implement('naturalDateTime', function(){
	return this.setUserOffset().format(Locale.get('Date.naturalDateTime'));
});

Date.implement('shortTime', function(){
	return this.setUserOffset().format(Locale.get('Date.shortTime'));
});
