window.addEvent('domready', function(){

	if (!Auth.User || Auth.Request.controller !== 'dashboard' || Auth.Request.action !== 'index' || Auth.Plan.special)
		return;

	/**
	 * DASHBOARD INTRODUCTION
	 *
	 * There is 4 type of actions created by users. Their
	 * ID's are converted to bits in order to form a bit-
	 * mask that controls what is shown in the dashboard.
	 * This conversion is done with the next formula:
	 *
	 *   bit = 2^(action_type_id - 1)
	 *
	 * | ACTION TYPE   | ID | CONVERTED |  N |
	 * | ------------- | -- | --------- |  - |
	 * | Calls         | 1  |  00000001 |  1 |
	 * | Meetings      | 2  |  00000010 |  2 |
	 * | Emails        | 3  |  00000100 |  4 |
	 * | Activities    | 4  |  00001000 |  8 |
	 * | WhatsApp      | 5  |  00010000 | 16 |
	 * | Video Call    | 6  |  00100000 | 32 |
	 *
	 * There is also a virtual action type; those assig-
	 * ned to “other user” from the current one. This
	 * virtual action type is 0b00010000.
	 *
	 * THE CODE BELOW
	 *
	 * The code is separated in this sections:
	 *
	 * 1. Variables
	 * 2. Class abstractions
	 * 3. Filters and request functions
	 * 4. Dashboard calendar functions
	 * 5. Schedule table functions
	 * 6. Start function
	 * 7. Permanent events
	 *
	 * The code in (6) initialize the filters from user
	 * cookie, collect the activities loaded in body and
	 * finally, calls for (4) and (5) functions.
	 *
	 * After that, the whole behaviour is controlled by
	 * user events (7).
	 * -------------------------------------------------- */

	/**
	 * Local variables
	 * -------------------------------------------------- */

	// Relation between ID's converted to powers of 2 and their action tyep.
	var ACTION_TYPE_BITS = {
		1: 'call',
		2: 'meeting',
		4: 'email',
		8: 'activity',
		16: 'whatsapp',
		32: 'video-call',
		64: 'default'
	};

	var HTML_POPOVER_TEMPLATE = [
		'<div class="popover dashboard-popover" role="tooltip">',
		'<div class="arrow"></div>',
		'<h3 class="popover-title"></h3>',
		'<div class="popover-content"></div></div>'
	].join('');

	var HTML_ACTION_IN_POPOVER = [
		'<p class="action action-{color_class}"><span class="label label-{color_class}">{action_time}</span>&nbsp;',
		'<a class="text-{color_class}" href="/{controller}/view/{client_id}/{project_id}{is_whatsapp}">{action_type}</a>:&nbsp;{text}</p>'
	].join('');

	var HTML_ACTION_TYPE_TITLE = [
		'<span class="total-actions text-{action_class}">',
		'<strong>{action_type}</strong>:&nbsp;{total_actions}</span>'
	].join('');

	// For @brand-info color (#5bc0de) the default settings must be:
	// - Min-max saturation [10 - 59]
	var MIN_SATURATION = 0;
	var MAX_SATURATION = 20;

	// - Min-max brightness [87 - 100]
	var MIN_BRIGHTNESS = 95;
	var MAX_BRIGHTNESS = 96;

	var TODAY = new Date();

	var DashboardContainer       = $('DashboardContainer');
	var DashboardContainerToggle = $('DashboardContainerToggle');

	var DashboardLoader          = $('DashboardLoader');
	var DashboardCalendar        = $('DashboardCalendar');
	var DashboardMonthYear       = $('DashboardMonthYear');
	var DashboardMonthPrev       = $('DashboardMonthPrev');
	var DashboardMonthNext       = $('DashboardMonthNext');
	var ScheduleTable            = $('ScheduleTable');
	var ScheduleMonthYear        = $('ScheduleMonthYear')

	var ActionCall               = $('ActionCall');
	var ActionMeeting            = $('ActionMeeting');
	var ActionEmail              = $('ActionEmail');
	var ActionActivity           = $('ActionActivity');
	var ActionGroup              = $('ActionGroup');
	var ActionWhatsApp           = $('ActionWhatsApp');
	var ActionVideoCall          = $('ActionVideoCall');

	var ActivitiesToday          = $('ActivitiesToday');
	var ActivitiesScheduled      = $('ActivitiesScheduled');
	var ActivitiesOverdue        = $('ActivitiesOverdue');
	var IndicatorEfficiency      = $('IndicatorEfficiency');
	var IndicatorPunctuality     = $('IndicatorPunctuality');

	var SalesProjected           = $('SalesProjected');
	var SalesSold                = $('SalesSold');
	var ProjectedUnits           = $('ProjectedUnits');
	var SoldUnits                = $('SoldUnits');
	var Quoted                   = $('Quoted');
	var QuotedSold               = $('QuotedSold');
	var TotalInvoiced            = $('TotalInvoiced');

	var TodayResponses           = $('TodayResponses');
	var TodayScheduled           = $('TodayScheduled');
	var ProgressToday            = $('ProgressToday');

	var CurrencySelect           = $$('.currency-select');
	var UnitSelect               = $$('.unit-select');
	var ShowGoalsProgress        = $$('.show-goals-progress');

	/**
	 * Dashboard class abstraction
	 * -------------------------------------------------- */

	var CalendarUser     = new SelectDropdown('DropdownUser');
	var CalendarCampaign = new SelectDropdown('DropdownCampaign');

	var filters = new Filters({
		User: CalendarUser,
		Campaign: CalendarCampaign,
		ActionCall: ActionCall,
		ActionMeeting: ActionMeeting,
		ActionEmail: ActionEmail,
		ActionActivity: ActionActivity,
		ActionGroup: ActionGroup,
		ActionWhatsApp: ActionWhatsApp,
		ActionVideoCall: ActionVideoCall,
		Quoted: Quoted,
		QuotedSold: QuotedSold,
		TotalInvoiced: TotalInvoiced
	});
	var stats = new Stats(filters);

	var currency_options   = {format: 'currency',	currency_symbol: CURRENCY_SYMBOL,	decimals: COMPANY_DECIMALS};
	var percentage_options = {format: 'percentage', decimals: COMPANY_DECIMALS};
	var units_options      = {format: 'units', suffix: ' ' + UNIT_SYMBOL};

	var fx_today           = new Fx.Counter(ActivitiesToday);
	var fx_scheduled       = new Fx.Counter(ActivitiesScheduled);
	var fx_overdue         = new Fx.Counter(ActivitiesOverdue);
	var fx_efficiency      = new Fx.Counter(IndicatorEfficiency, percentage_options);
	var fx_punctuality     = new Fx.Counter(IndicatorPunctuality, percentage_options);
	var fx_projected       = new Fx.Counter(SalesProjected, currency_options);
	var fx_sold            = new Fx.Counter(SalesSold, currency_options);
	var fx_projected_units = new Fx.Counter(ProjectedUnits, units_options);
	var fx_sold_units      = new Fx.Counter(SoldUnits, units_options);
	var fx_quoted          = new Fx.Counter(Quoted, currency_options);
	var fx_quoted_sold     = new Fx.Counter(QuotedSold, currency_options);
	var fx_total_invoiced  = new Fx.Counter(TotalInvoiced, currency_options);
	var fx_today_responses = new Fx.Counter(TodayResponses);
	var fx_today_scheduled = new Fx.Counter(TodayScheduled);

	/**
	 * Filters and request functions
	 * -------------------------------------------------- */
	var activities = {};
	var activities_requested = ['/dashboard/schedule_actions/' + TODAY.format('%Y-%m-%d')];

	var ActivitiesRequest = new Request.JSON({
		method: 'post',
		onRequest: function()
		{
			DashboardLoader.removeClass('hide');
		},
		onSuccess: function(json, text)
		{
			if (json.actions)
			{
				update_activities(json.actions);
			}
		},
		onFailure: function(xhr)
		{
			DashboardLoader.addClass('hide');
		},
		onError: function(text, error)
		{
			DashboardLoader.addClass('hide');
		}
	});

	var user_update = function()
	{
		filters.update();
		stats.update(filters);

		load_schedule();
		update_activities_counter();
		update_urls();
	}

	var update_activities = function(actions)
	{
		DashboardLoader.removeClass('hide');

		var last_hour = null;
		var total_actions = actions.length;

		for (var n = 0; n < total_actions; n++)
		{
			var date_string = actions[n].action_date.split(' ')[0];
			var hour = Date.parse(actions[n].action_date).get('hours') + ':00';

			if (activities[date_string] === undefined)
				activities[date_string] = {};

			if (last_hour !== hour || activities[date_string][hour] === undefined)
			{
				activities[date_string][hour] = [];
				last_hour = hour;
			}

			activities[date_string][hour].push(actions[n]);
		}

		load_schedule();
	}

	/**
	 * Dashboard calendar functions
	 * -------------------------------------------------- */

	var dashboard_select_day = function(event)
	{
		if (this.get('data-date') === DashboardCalendar.get('data-date'))
			return;

		var new_date = Date.parse(this.get('data-date')).setUserOffset();
		var old_date = Date.parse(DashboardCalendar.get('data-date')).setUserOffset();

		if (!new_date || !old_date)
			return;

		DashboardCalendar.set('data-date', this.get('data-date'));
		var day = DashboardCalendar.getElement('td[data-date="' + this.get('data-date') + '"]');

		if (day && old_date.get('month') === new_date.get('month'))
		{
			var tr = day.getParent('tr');

			if (!tr.hasClass('warning'))
			{
				DashboardCalendar.getElement('tr.warning').removeClass('warning');
				tr.addClass('warning');
			}
		}
		preload_schedule(new_date);
	}

	var draw_dashboard_calendar = function(date)
	{
		var weekdays = Locale.get('Date.days_abbr').slice(0);
		weekdays = weekdays.concat(weekdays.splice(0, START_DAY));

		var year = date.get('year');
		var month = date.get('month');
		var today_format = TODAY.format('%Y-%m-%d');

		var days_diff = new Date(year, month, 1).getDay() - START_DAY;
		var extra_days = days_diff < 0 ? days_diff + 7 : days_diff;
		var day = new Date(year, month, (extra_days * -1));

		var dashboard_title = Locale.get('Date.months')[month].capitalize() + ', ' + date.get('year');
		var schedule_date = ScheduleTable.get('data-date');

		DashboardCalendar.set('data-date', date.format('%Y-%m-%d'));
		DashboardMonthYear.set('text', dashboard_title);
		DashboardMonthPrev.set('data-date', new Date(year, month, 0).format('%Y-%m-%d'));
		DashboardMonthNext.set('data-date', new Date(year, month + 1, 1).format('%Y-%m-%d'));

		var tbody = new Element('tbody');

		for (var n = 1, tr = null; n <= 42; n++)
		{
			day.increment('day', 1);

			if (tr === null)
				tr = new Element('tr', {'class': 'week'});

			var day_format = day.format('%Y-%m-%d');
			var day_number = day.getDate();

			if (day_format === schedule_date)
				tr.addClass('warning');

			var td = new Element('td', {'class': 'day', 'data-date': day_format});

			if (day.get('month') !== month)
				td.addClass('other-month');

			if (day_format === today_format)
			{
				new Element('span', {'class': 'circle', 'text': day_number}).inject(td);
				td.addClass('today').inject(tr);
			}
			else
			{
				var day_activities = 0;
				if (DashboardDensity[day_format])
				{
					for (var i = 0, limit = DashboardDensity[day_format].length; i < limit; i++)
					{
						if (!filters.match(DashboardDensity[day_format][i]))
							continue;

						day_activities += DashboardDensity[day_format][i].total;
					}
				}
				if (day_activities > 0)
				{
					var max_diff = stats.max_per_day - stats.min_per_day;
					var saturation = (day_activities * (MAX_SATURATION - MIN_SATURATION) / max_diff) + MIN_SATURATION;
					var brightness = MAX_BRIGHTNESS - (day_activities * (MAX_BRIGHTNESS - MIN_BRIGHTNESS) / max_diff);

					var color = new Color('#5bc0de').setBrightness(brightness).setSaturation(saturation);
					new Element('span', {
						'class': 'circle',
						'title': day_activities,
						'text': day_number
					}).setStyle('background-color', color).inject(td);
					td.addClass('busy').inject(tr);
				}
				else
				{
					td.set('text', day_number).inject(tr);
				}
			}

			td.addEvent('click', dashboard_select_day);

			if ((n % 7) === 0)
			{
				tr.inject(tbody);
				tr = null;
			}
		}
		DashboardCalendar.getElements('td').removeEvents('click');
		tbody.replaces(DashboardCalendar.getElement('tbody'));
	}

	var update_activities_counter = function()
	{
		fx_today.start(ActivitiesToday.get('text').toInt(), stats.activities_today);
		fx_scheduled.start(ActivitiesScheduled.get('text').toInt(), stats.activities_scheduled);
		fx_overdue.start(ActivitiesOverdue.get('text').toInt(), stats.activities_overdue);

		if (stats.efficiency < 0)
		{
			IndicatorEfficiency.set('text', '—');
		}
		else
		{
			fx_efficiency.start(IndicatorEfficiency.get('text').replace('%', '').toInt(), stats.efficiency);
		}

		if (stats.punctuality < 0)
		{
			IndicatorPunctuality.set('text', '—');
		}
		else
		{
			fx_punctuality.start(IndicatorPunctuality.get('text').replace('%', '').toInt(), stats.punctuality);
		}

		if (UNITS_IN_PROJECTS)
		{
			fx_projected_units.start(ProjectedUnits.get('data-value').toInt(), stats.projected_units);
			fx_projected_units.options.unit_code = UNIT_SYMBOL;
			fx_sold_units.start(SoldUnits.get('data-value').toInt(), stats.sold_units);
			fx_sold_units.options.unit_code = UNIT_SYMBOL;

			ProjectedUnits.set('data-value', stats.projected_units);
			SoldUnits.set('data-value', stats.sold_units);
		}

		fx_projected.options.currency_symbol = CURRENCY_SYMBOL;
		fx_sold.options.currency_symbol = CURRENCY_SYMBOL;
		fx_quoted.options.currency_symbol = CURRENCY_SYMBOL;
		fx_quoted_sold.options.currency_symbol = CURRENCY_SYMBOL;
		fx_total_invoiced.options.currency_symbol = CURRENCY_SYMBOL;

		fx_projected.start(SalesProjected.get('data-value').toFloat(), stats.projected);
		fx_sold.start(SalesSold.get('data-value').toFloat(), stats.sold);
		fx_quoted.start(Quoted.get('data-value').toFloat(), stats.quoted);
		fx_quoted_sold.start(QuotedSold.get('data-value').toFloat(), stats.quoted_sold);
		fx_total_invoiced.start(TotalInvoiced.get('data-value').toFloat(), stats.total_invoiced);

		SalesProjected.set('data-value', stats.projected);
		SalesSold.set('data-value', stats.sold);
		Quoted.set('data-value', stats.quoted);
		QuotedSold.set('data-value', stats.quoted_sold);
		TotalInvoiced.set('data-value', stats.total_invoiced);

		fx_today_responses.start(TodayResponses.get('text').toInt(), stats.today_responses);
		fx_today_scheduled.start(TodayScheduled.get('text').toInt(), stats.today_scheduled);

		var progress_today = stats.today_scheduled === 0 ? 0 : stats.today_responses / stats.today_scheduled * 100;

		ProgressToday.setStyle('width', progress_today.formatPercentage(1));
		ProgressToday.getParent().toggleClass('active', progress_today < 100);
	}

	/**
	 * Schedule table functions
	 * -------------------------------------------------- */

	var draw_schedule_today_column = function()
	{
		ScheduleTable.getElements('tbody td.today').removeClass('today');
		ScheduleTable.getElements('tbody td.now').removeClass('now');

		var today_date = TODAY.format('%Y-%m-%d');
		var th = ScheduleTable.getElement('thead th.day[data-date="' + today_date + '"]');

		if (!th)
			return;

		var day = th.getParent().getChildren('th').indexOf(th);
		var hours = ScheduleTable.getElements('tbody td.day' + day);
		var today_time = TODAY.get('hours') + ':00';

		hours.addClass('today');
		for (var n = 0; n < hours.length; n++)
		{
			if (hours[n].get('data-time') === today_time)
			{
				hours[n].addClass('now');
				break;
			}
		}
	}

	var load_popover = function()
	{
		if (this.hasClass('popover-loaded'))
			return;

		var td = this.getParent();
		var hour = td.get('data-time');
		var index = td.getParent().getChildren('td').indexOf(td);
		var date = ScheduleTable.getChildren('thead tr th')[index].get('data-date');

		var scope_user_id = filters.user_id ? filters.user_id : Auth.User.id;

		var total_actions = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0};

		var popover_content = '';
		for (var n = 0; n < activities[date][hour].length; n++)
		{
			var activity = activities[date][hour][n];

			if (!filters.match(activity))
				continue;

			var action_time = activity.action_date.split(' ')[1];

			var popover_action = {
				client_id:   activity.client_id,
				project_id:  activity.project_id,
				action_type: ACTION_TYPES[activity.action_type_id].singular,
				color_class: ACTION_TYPES[activity.action_type_id].class,
				text:        activity.client_name.replace(/ /g, '&nbsp;'),
				controller:  activity.prospectus ? 'prospects' : 'clients',
				action_time: action_time.slice(0, action_time.lastIndexOf(':')),
				is_whatsapp: activity.action_type_id == '5' ? '?whatsapp_event=true' : ''
			};

			if (activity.user_assigned_id != scope_user_id)
				popover_action.color_class = 'default';

			if (activity.action_type_id == '5')
			{
				popover_content += HTML_ACTION_IN_POPOVER.substitute(popover_action);
			}
			else
			{
				popover_content += HTML_ACTION_IN_POPOVER.substitute(popover_action);
			}

			total_actions[activity.action_type_id]++;
		}

		var popover_title = '';
		for (var i = 1; i <= 6; i++)
		{
			if (total_actions[i] === 0)
				continue;

			var action_type_title = {
				action_class:  ACTION_TYPES[i].class,
				action_type:   total_actions[i] === 1 ? ACTION_TYPES[i].singular : ACTION_TYPES[i].plural,
				total_actions: total_actions[i]
			};

			popover_title += HTML_ACTION_TYPE_TITLE.substitute(action_type_title);
		}

		jQuery(this).popover({
			template: HTML_POPOVER_TEMPLATE,
			title: popover_title,
			content: '<div class="actions">' + popover_content + '</div>',
			html: true
		}).popover('show').on('shown.bs.popover', function(){
			jQuery('.popover-loaded').not(this).popover('hide');
		});

		this.addClass('popover-loaded');
	}

	var load_schedule = function()
	{
		DashboardLoader.removeClass('hide');

		var scope_user_id = filters.user_id ? filters.user_id : Auth.User.id;
		var days = ScheduleTable.getElements('thead tr th.day');

		for (var n = 0; n < days.length; n++)
		{
			var date = days[n].get('data-date');
			var hours = ScheduleTable.getElements('tbody td.day' + (n + 1));

			for (var h = 0; h < hours.length; h++)
			{
				hours[h].empty();

				if (!activities[date])
					continue;

				var time = h + ':00';

				if (!activities[date][time])
					continue;

				var action_type = 0;
				var actions = 0;

				for (var i = 0; i < activities[date][time].length; i++)
				{
					var activity = activities[date][time][i];

					if (!filters.match(activity))
						continue;

					// Conversión del ID de tipo de acción a un dígito binario.
					var action_type_bit = Math.pow(2, activity.action_type_id - 1);

					// Suma binaria del 7º bit (2^64) si la acción pertenece a otro usuario o grupo.
					if (activity.user_assigned_id != scope_user_id)
						action_type = action_type | 64;

					// Suma binaria de los bits 1, 2, 4, 8 y 16, correponsdientes a los tipos de acción.
					action_type = action_type | action_type_bit;
					actions++;
				}

				if (actions === 0)
					continue;

				// Si son más de un tipo de acciones y al menos una pertenece a otro usuario, será “default”.
				if (ACTION_TYPE_BITS[action_type ^ 64] && (action_type & 64))
					action_type = 64;

				var badge_type = ACTION_TYPE_BITS[action_type] ? ACTION_TYPE_BITS[action_type] : 'inverse';
				var badge = new Element('span', {'class': 'badge badge-' + badge_type, 'text': actions});

				badge.addEvent('click', load_popover);
				badge.inject(hours[h]);
			}
		}
		draw_dashboard_calendar(Date.parse(ScheduleTable.get('data-date')).setUserOffset());

		DashboardLoader.addClass('hide');
	}

	var preload_schedule = function(date)
	{
		ScheduleTable.set('data-date', date.format('%Y-%m-%d'));

		var new_url = '/dashboard/schedule_actions/' + date.format('%Y-%m-%d');

		if (activities_requested.contains(new_url) === false)
		{
			activities_requested.push(new_url);
			ActivitiesRequest.send({url: new_url});
		}

		var correction = date.get('day') - START_DAY;

		date.decrement('day', correction < 0 ? correction + 7 : correction);

		var days = ScheduleTable.getElements('thead th.day');

		for (var n = 0; n < 7; n++, date.increment('day'))
		{
			days[n].set('data-date', date.format('%Y-%m-%d'));
			days[n].getElement('.full').set('text', Locale.get('Date.days')[date.get('day')] + ', ' + date.getDate());
			days[n].getElement('.abbr').set('text', Locale.get('Date.days_abbr')[date.get('day')] + ', ' + date.getDate());
		}

		ScheduleMonthYear.set('text', DashboardMonthYear.get('text'));

		draw_schedule_today_column();
		load_schedule();
	}

	var update_urls = function()
	{
		var currency_code = 'currency_code:' + DASHBOARD_CURRENCY + '/';
		var date_range  = 'range:' + COMPANY_DATE_RANGE.replace(/\s+/g, 'T') + '/';
		var campaign_id = filters.campaign_id > 0 ? 'campaign:' + filters.campaign_id + '/' : '';
		var user_id     = filters.user_id ? 'user:' + filters.user_id + '/' : '';

		Quoted.set('href', '/price_quotes/index/list:ongoing/' + date_range + user_id + currency_code);
		QuotedSold.set('href', '/price_quotes/index/list:approved/' + date_range + user_id + currency_code);
		SalesProjected.set('href', '/projects/index/' + date_range + user_id + campaign_id + currency_code);
		SalesSold.set('href', [
			'/projects/index/status_action:terminal_completed/',
			date_range,
			user_id,
			campaign_id,
			currency_code
		].join(''));
		if (UNITS_IN_PROJECTS)
		{
			ProjectedUnits.set('href', '/projects/index/' + date_range + user_id + campaign_id + currency_code);
			SoldUnits.set('href', [
				'/projects/index/status_action:terminal_completed/',
				date_range,
				user_id,
				campaign_id,
				currency_code
			].join(''));
		}
		TotalInvoiced.set('href', '/invoices/index/list:invoiced/' + date_range + user_id + campaign_id);
	}

	/**
	 * Start function
	 * -------------------------------------------------- */

	;(function(){
		var schedule_month = Locale.get('Date.months')[TODAY.get('month')].capitalize() + ', ' + TODAY.get('year');
		ScheduleMonthYear.set('text', schedule_month);

		update_activities(DashboardActions);
		update_activities_counter();
		update_urls();

		draw_dashboard_calendar(new Date());
		draw_schedule_today_column();
	})();

	/**
	 * Permanent events
	 * -------------------------------------------------- */

	var fx_calendar = new Fx.Slide(DashboardContainer, {duration: 300}).hide();
	DashboardContainer.removeClass('hide');
	DashboardContainerToggle.addEvent('click', function(event) {
		event.stop();
		fx_calendar.toggle();
	});

	SchedulePrevWeek.addEvent('click', function(event) {
		event.stop();
		var new_date = Date.parse(ScheduleTable.get('data-date')).setUserOffset().decrement('week', 1);

		preload_schedule(new_date);
	});
	ScheduleNextWeek.addEvent('click', function(event) {
		event.stop();
		var new_date = Date.parse(ScheduleTable.get('data-date')).setUserOffset().increment('week', 1);

		preload_schedule(new_date);
	});

	DashboardMonthPrev.addEvent('click', dashboard_select_day);
	DashboardMonthNext.addEvent('click', dashboard_select_day);

	if (CalendarUser)
		CalendarUser.addEvent('change', user_update);

	if (CalendarCampaign)
		CalendarCampaign.addEvent('change', user_update);

	var toggle_action_type = function(event)
	{
		event.stop();
		this.toggleClass('active');
		user_update();
	}

	ActionCall.addEvent('click', toggle_action_type);
	ActionMeeting.addEvent('click', toggle_action_type);
	ActionEmail.addEvent('click', toggle_action_type);
	ActionActivity.addEvent('click', toggle_action_type);
	ActionGroup.addEvent('click', toggle_action_type);
	ActionWhatsApp.addEvent('click', toggle_action_type);
	ActionVideoCall.addEvent('click', toggle_action_type);

	var general_statistics = $$('.general-statistics');
	var unit_statistics = general_statistics.getElements('.units-stats');
	var currency_statistics = general_statistics.getElements('.currency-stats');
	var user_goals_ui = $$('.user-goal-progress, .user-goal-label');
	var UserGoalsProgress = $$('.user-goal-progress');

	CurrencySelect.addEvent('click', function(event){
		var currency = this.get('currency');
		var DashboardCurrencyChange = new Request.JSON({
			method: 'post',
			url: '/dashboard/currency_for_stats/currency:' + currency,
			onSuccess: function(response, text)
			{
				this.getParent('li').getSiblings().removeClass('active');
				this.getParent('li').addClass('active');

				DashboardSales     = response.sales     || 0;
				DashboardQuotes    = response.quotes    || 0;
				DashboardInvoicing = response.invoicing || 0;

				DASHBOARD_CURRENCY = currency;
				CURRENCY_SYMBOL = response.currency_symbol || '$';
				user_update();
			}.bind(this),
		});

		DashboardCurrencyChange.send();

		if (unit_statistics)
		{
			unit_statistics.each(function(element){
				element.addClass('hide');
			});

			currency_statistics.each(function(element){
				element.removeClass('hide');
			});
		}

		general_statistics.removeClass('hide');
		user_goals_ui.addClass('hide');
	});

	if (UnitSelect)
	{
		UnitSelect.addEvent('click', function(){
			var unit = this.get('unit');

			var DashboardUnitChange = new Request.JSON({
				method: 'post',
				url: '/dashboard/unit_for_stats/unit:' + unit,
				onSuccess: function(response, text)
				{
					this.getParent('li').getSiblings().removeClass('active');
					this.getParent('li').addClass('active');

					DashboardUnitStats = response.unit_stats || 0;
					UNIT_SYMBOL = ' ' + UNITS_SYMBOLS[unit];
					user_update();
				}.bind(this),
			});

			DashboardUnitChange.send();

			unit_statistics.each(function(element){
				element.removeClass('hide');
			});

			currency_statistics.each(function(element){
				element.addClass('hide');
			});

			general_statistics.removeClass('hide');
			user_goals_ui.addClass('hide');
		});
	}

	ShowGoalsProgress.addEvent('click', function(event){
		this.getParent('li').getSiblings().removeClass('active');
		this.getParent('li').addClass('active');
		general_statistics.addClass('hide');
		user_goals_ui.removeClass('hide');
	});
	UserGoalsProgress.each(function(user_goal_progress){
		var measure_format = user_goal_progress.get('format');
		var progress_bar = user_goal_progress.getElement('.progress-bar');
		var progress_value = user_goal_progress.getElement('.progress-value');
		var progress_percentage = user_goal_progress.getElement('.progress_percentage');

		var options = {
			format: measure_format,
			decimals: 0
		};

		if (measure_format === 'currency')
		{
			var goal_data = JSON.parse(user_goal_progress.getElement('.goal-data').get('data-value'));
			options['currency_symbol'] = goal_data.currency_symbol;
		}

		setTimeout(function(){
			progress_bar.setStyle('width', parseInt(progress_bar.get('data-value')) + '%');
		}, 150);

		var fx = new Fx.Counter(progress_value, options);
		fx.start(0, parseInt(progress_value.get('data-value')));

		var fx_per = new Fx.Counter(progress_percentage, {format: 'percentage', decimals: 2});
		fx_per.start(0, parseInt(progress_percentage.get('data-value')));
	});

	var toggleDashboardCalendar = function()
	{
		var ScheduleContainer = $('ScheduleContainer');

		if (!ScheduleContainer)
			return;

		ScheduleContainer.toggleClass('hide', (window.innerWidth >= window.innerHeight) === false);
	};

	window.addEvent('resize', function(){
		// Set in a timeout because I'm trying to compensate natural delay from JS.
		setTimeout(function(){
			toggleDashboardCalendar();
		}, (190)); // 190ms is human reaction time.
	});

	toggleDashboardCalendar();
});
