var LiquidTable = new Class({
	Implements: Options,
	dragging: {
		element:      null,
		position:     null,
		new_position: null,
		offsetX:      0,
		offsetY:      0
	},
	table: null,
	draggables: [],
	levels: [],
	initialize: function(table, options)
	{
		this.setOptions(options);
		this.table = table;

		document.addEvent('mouseup',   this.mouseUpEvent.bind(this));
		document.addEvent('mousemove', this.mouseMoveEvent.bind(this));

		this.table.getElements('.draggable').each((function(draggable){
			this.addDraggable(draggable);
		}).bind(this));
	},
	addDraggable: function(draggable)
	{
		this.draggables.push(draggable);

		draggable.addEvent('mousedown', this.mouseDownEvent.bind(this));
	},
	removeDraggable: function(draggable)
	{
		draggable.removeEvents('mousedown');
	},
	mouseDownEvent: function(event)
	{
		event.stop();

		var row = $(event.target).getParent('tr');

		this.dragging = {
			element:      this.table.clone(),
			position:     null,
			new_position: null,
			offsetX:      event.event.offsetX,
			offsetY:      event.event.offsetY,
		};

		this.dragging.element.setStyles({
			'width':            this.table.getSize().x + 'px',
			'position':         'absolute',
			'background-color': '#fff',
			'left':             (event.event.pageX - this.dragging.offsetX) + 'px',
			'top':              (event.event.pageY - this.dragging.offsetY) + 'px',
			'will-change':      'left, top',
			'z-index':          1051,
			'box-shadow':       '0px 0px 20px #eee',
		});
		this.dragging.element.getElement('thead').destroy();
		this.dragging.element.getElement('tbody').empty().grab(row.clone());

		this.table.getParent('body').grab(this.dragging.element);

		this.levels = [];

		for (var i = 0, rows = this.table.getElements('tbody tr'); i < rows.length; i++)
		{
			if (row === rows[i])
				this.dragging.position = i;

			var position = rows[i].getPosition();
			var size     = rows[i].getSize();

			this.levels.push({
				row: rows[i],
				height: position.y + parseInt(size.y / 2)
			});
		}
	},
	mouseUpEvent: function(event)
	{
		if (this.levels.length === 0 || !this.dragging.element)
			return;

		if (this.dragging.new_position !== null)
		{
			var moving = this.levels[this.dragging.position].row.dispose();

			if (this.levels[this.dragging.new_position])
			{
				moving.inject(this.levels[this.dragging.new_position].row, 'before');
			}
			else if (this.levels[this.dragging.new_position - 1])
			{
				moving.inject(this.levels[this.dragging.new_position - 1].row, 'after');
			}
		}

		this.table.getElements('tbody > tr.before-drop, tbody > tr.after-drop').removeClass('before-drop').removeClass('after-drop');

		if (this.dragging.element)
		{
			(function(element){
				element.destroy();
			}).delay(250, null, this.dragging.element);

			this.dragging.element.set('morph', {duration: 200, transition: Fx.Transitions.Quad.easeOut});
			this.dragging.element.morph({opacity: 0});
		}

		this.dragging.element = null;
		this.dragging = {};
	},
	mouseMoveEvent: function(event)
	{
		if (!this.dragging.element)
			return;

		this.dragging.element.setStyles({
			'left': (event.event.pageX - this.dragging.offsetX) + 'px',
			'top': (event.event.pageY - this.dragging.offsetY) + 'px',
		});

		this.showNewPosition(event.event.pageY);
	},
	showNewPosition: function(new_height)
	{
		if (this.levels.length === 0)
			return;

		var new_position = 0;
		while (this.levels[new_position] && new_height > this.levels[new_position].height)
		{
			new_position++;
		}

		var has_change = !((new_position - 1) === this.dragging.position || (new_position) === this.dragging.position);

		if (this.dragging.new_position !== new_position)
		{
			this.dragging.new_position = new_position;
			this.table.getElements('tbody > tr.before-drop, tbody > tr.after-drop').removeClass('before-drop').removeClass('after-drop');

			if (this.levels[new_position - 1])
				this.levels[new_position - 1].row.addClass('before-drop');

			if (this.levels[new_position])
				this.levels[new_position].row.addClass('after-drop');
		}
		if (!has_change)
		{
			this.dragging.new_position = null;
		}
	}
});
