window.addEvent('domready', function(){

	var _valid_actions = ['create'];
	if (Auth.Request.controller !== 'price_quotes' || _valid_actions.indexOf(Auth.Request.action) === -1)
		return;

	var count = 0;
	var categories_count      = 0;
	var PriceQuoteKeywords    = $('PriceQuoteKeywords');
	var ProductSearchResults  = $('ProductSearchResults');
	var TableProducts         = $('TableProducts');
	var SearchButton          = $('SearchButton');
	var ToggleEmailQuotation  = $('ToggleEmailQuotation');
	var EmailQuotation        = $('EmailQuotation');
	var AddCc                 = $('AddCc');
	var Ccs                   = $('Ccs');
	var CcMails               = $('CcMails');
	var Subtotal              = $('Subtotal');
	var Total                 = $('Total');
	var SaveQuotation         = $('SaveQuotation');
	var SendQuotation         = $('SendQuotation');
	var SendForm              = $('SendForm');
	var SaveForm              = $('SaveForm');
	var ProductSaveQuotation  = $('ProductSaveQuotation');
	var ProductSendQuotation  = null;
	var TemplateToClone       = $('TemplateToClone').dispose();
	var CategoryRowClone      = $('CategoryRowClone');
	var CurrencyCode          = null;
	var EmailInputs           = $$('.validate-email');
	var ToggleUserAssigned    = $('ToggleUserAssigned');
	var UserAssignedGroup     = $('UserAssignedGroup');
	var PriceQuoteMailSubject = $('PriceQuoteMailsSubject');
	var PriceQuoteTitle       = $('PriceQuoteTitle');
	var PriceQuoteCc          = $('PriceQuoteCc');
	var TaxSwitch             = $('TaxSwitch');
	var Tax                   = $('Tax');
	var Withhold              = $('Withhold');
	var PriceQuoteTax         = $('PriceQuoteTax');
	var GlobalDiscount        = $('GlobalDiscount');
	var IEPSTotal             = $('IEPSTotal');

	tinymce.init({
		selector: 'textarea.tinymce',
		height: 300,
		branding:false,
		statusbar: false,
		contextmenu: false
	});

	ToggleUserAssigned.addEvent('click', function(){
		this.removeEvents('click');
		this.getParent().destroy();

		UserAssignedGroup.getElements('.hide').removeClass('hide');
	});

	TemplateToClone.removeClass('hide');

	if (TaxSwitch)
	{
		TaxSwitch.addEvent('click', function(){
			calculate_total();
		});
	}

	if (PriceQuoteTax)
	{
		PriceQuoteTax.addEvent('change', function(){
			calculate_total();
		});
	}

	if (GlobalDiscount && PRICE_QUOTE_EDIT_DISCOUNT)
	{
		GlobalDiscount.addEvent('change', function(){
			var discount = 0;
			if (PRICE_QUOTE_MAX_DISCOUNT < parseFloat(this.value))
			{
				this.set('value', PRICE_QUOTE_MAX_DISCOUNT);
			}

			discount = parseFloat(this.value);
			TableProducts.getElements('.discount').each(function(ProductDiscount){
				ProductDiscount.set('value', discount);
			});

			calculate_total();
		});
	}

	EmailQuotation.addEvents({
		toggle: function()
		{
			this.fireEvent(this.hasClass('collapsed') ? 'show' : 'hide');
		},
		show: function()
		{
			ProductSaveQuotation.setProperty('id', 'ProductSendQuotation');
			ProductSendQuotation = $('ProductSendQuotation');
			EmailInputs.each(function(input){
				input.removeProperty('disabled');
			});
			SaveQuotation.addClass('hidden');

			this.removeClass('collapsed');
		},
		hide: function()
		{
			this.addClass('collapsed');
			ProductSendQuotation.setProperty('id', 'ProductSaveQuotation');
			ProductSaveQuotation = $('ProductSaveQuotation');

			EmailInputs.each(function(input){
				input.setProperty('disabled', 'disabled');
			});

			SaveQuotation.removeClass('hidden');
		}
	});
	ToggleEmailQuotation.addEvent('click', function(){
		if (this.hasClass('send'))
		{
			SendQuotation.addClass('hide');
			this.removeClass('send');
			this.set('html', Locale.get('Price.sendPriceQuote'));
		}
		else
		{
			SendQuotation.removeClass('hide');
			this.addClass('send');
			this.set('html', Locale.get('Price.dontSendByMail'));
			if (PriceQuoteMailSubject.get('value') === '')
			{
				PriceQuoteMailSubject.set('value', PriceQuoteTitle.get('value'));
			}
		}

		EmailQuotation.fireEvent('toggle');
	});

	if (SEND_QUOTATION)
	{
		ToggleEmailQuotation.fireEvent('click');
		EmailQuotation.addClass('in');
	}

	var add_to_cc = function(event)
	{
		var mail = this.get('html');
		var cc_value = PriceQuoteCc.get('value');
		mail = cc_value !== '' ? ',' + mail : mail;
		PriceQuoteCc.set('value', cc_value + mail);
	}

	AddCc.addEvent('click', function(){
		Ccs.removeClass('hide');
		Ccs.getElements('input').each(function(input, i){
			input.addClass('validate');
		});
		if (CcMails)
		{
			CcMails.removeClass('hidden');
			CcMails.getElements('span').forEach(function(mail){
				mail.addEvent('click', add_to_cc);
			});
		}
		this.getParent().getParent().destroy();
	});

	PriceQuoteKeywords.addEvent('keydown', function(event){
		if (event.key === 'enter')
		{
			search();
		}
	});

	EmailInputs.each(function(input, i){
		input.addEvent('change', function(){
			this.set('value', this.get('value').replace(/\s/g,''));
		});
	});

	SaveForm.addEvent('click', function(){
		var save = true;
		var LoaderSaveQuote = $('LoaderSaveQuote');
		var categories_list = TableProducts.getElements('tbody .category-row:not(#CategoryRowClone)');

		LoaderSaveQuote.removeClass('hide');

		$$('.has-error').removeClass('has-error');

		if (TableProducts.getElements('tbody .product-row').length < 1)
		{
			save = false;
			PriceQuoteKeywords.getParent().addClass('has-error');
		}
		if (categories_list.length)
		{
			add_cateogry_to_products(categories_list);
			$('PriceQuoteIsProject').set('value', true);
		}

		if (save)
		{
			SaveForm.set('disabled', 'disabled');
			SaveForm.removeEvents('click');

			ProductSaveQuotation.submit();
		}
		else
		{
			LoaderSaveQuote.addClass('hide');
		}
	});

	SendForm.addEvent('click', function(){

		var send = true;
		var PriceQuoteMailsMessage = $('PriceQuoteMailsMessage');
		var mce_panel = $$('.tox-tinymce');
		var LoaderSendQuote = $('LoaderSendQuote');
		var categories_list = TableProducts.getElements('tbody .category-row:not(#CategoryRowClone)');

		LoaderSendQuote.removeClass('hide');

		$$('.has-error').removeClass('has-error');
		$$('.validate:not(.validate-email)').each(function(item){
			if (item.get('value') === '')
			{
				send = false;
				item.getParent().addClass('has-error');
			}
		});

		$$('.validate.validate-email').each(function(email_input, i){
			var fieldValue = email_input.get('value');

			if (fieldValue.length && !isEmailValid(fieldValue))
			{
				send = false;
				email_input.getParent().addClass('has-error');
			}
		});

		mce_panel.setStyle('border-color', '#9e9e9e');

		if (tinyMCE.activeEditor.getContent() === '')
		{
			send = false;
			mce_panel.setStyle('border-color', 'red');
			PriceQuoteMailsMessage.focus();
			tinyMCE.activeEditor.focus();
		}
		if (TableProducts.getElements('tbody tr:not(.category-row)').length < 1)
		{
			send = false;
			PriceQuoteKeywords.getParent().addClass('has-error');
		}
		if (categories_list.length)
		{
			add_cateogry_to_products(categories_list);
			$('PriceQuoteIsProject').set('value', true);
		}
		if (send)
		{
			SendForm.set('disabled', 'disabled');
			SendForm.removeEvents('click');

			ProductSendQuotation.submit();
		}
		else
		{
			LoaderSendQuote.addClass('hide');
		}
	});

	SearchButton.addEvent('click', function(event){
		search();
	});

	jQuery('#ProductSearch').on('hide.bs.dropdown', function() {
		return false;
	});

	jQuery('#ProductSearch').on('show.bs.dropdown', function() {
		return false;
	});

	document.addEvent('click', function(event){
		if (!$(event.target).hasClass('product'))
		{
			PriceQuoteKeywords.getParent().removeClass('open');
		}
	});

	var request_search = new Request.JSON({
		url: '/products/search.json',
		onRequest: function()
		{
			var old_results = ProductSearchResults.getElements('li.result');

			old_results.removeEvents();
			old_results.destroy();
			PriceQuoteKeywords.getParent().removeClass('open');
		},
		onSuccess: function(result)
		{
			if (result.products.length === 0)
				return;

			PriceQuoteKeywords.getParent().addClass('open');
			ProductSearchResults.removeClass('hide');
			for (var key in result.products)
			{
				if (!result.products.hasOwnProperty(key))
				{
					continue;
				}

				var product         = result.products[key];
				var price           = parseFloat(product.Product.price);
				var currency_code   = product.Product.currency_code;

				var item = '<small class="pull-right text-muted">' + price.format({
					decimal: '.',
					group: ',',
					decimals: COMPANY_DECIMALS,
					prefix: product.Product.currency_symbol
				});
				item += ' <small>' + currency_code + '</small></small>';
				item += '<span style="margin-right: 120px">';
				item += (PRICE_QUOTE_PRODUCT_SKU && product.Product.sku) ? product.Product.sku + ' - ' : '';
				item += product.ProductLine.name ? product.ProductLine.name + ' — ' : '';
				item += product.Product.name;
				item += '</span>';

				var li = new Element('li.result', {
					'style': 'cursor: pointer',
					'data-currency_code': product.Product.currency_code
				});
				new Element('a.product', {
					'html': item,
					'data-product': JSON.encode(product.Product)
				}).inject(li);

				ProductSearchResults.adopt(li);
			}
			ProductSearchResults.getElements('li.result').addEvent('click', function(event){
				event.stop();
				add_product(JSON.decode(this.getElement('a.product').get('data-product')));
			});
		}
	});

	var PriceQuoteCategories = $('PriceQuoteCategories');
	if (PriceQuoteCategories)
	{
		PriceQuoteCategories.addEvent('change', function(){
			add_category(PriceQuoteCategories.get('value'));
		});

	}

	var add_category = function(Category){
		var is_already_added = TableProducts.getElement('[value=' + Category + ']');
		if (!Category || is_already_added)
		{
			return;
		}
		categories_count++;
		var category = CategoryRowClone.clone();

		category.removeClass('hide');
		category.addClass('category-row');
		category.getElement('.category-name').append(Category);
		category.getElement('.category-name__input').setProperties({
			'name': 'data[PriceQuote][category][' + categories_count + ']',
			'value': Category
		});
		liquid_table.addDraggable(category.getElement('.draggable'));

		category.getElement('.category-trash').addEvent('click', remnove_category);
		return category.inject(TableProducts.getElement('tbody'), 'bottom');
	};

	var remnove_category = function(event)
	{
		event.stop();

		var category_row = this.getParent('tr');

		liquid_table.removeDraggable(category_row.getElement('.draggable'));
		category_row.getElements('input .category-trash').removeEvents();
		category_row.destroy();
	}

	var add_cateogry_to_products = function(categories_list)
	{
		categories_list.each(function(Category) {
			var category_name = Category.getElement('.category-name__input').get('value');
			var Next = Category.getNext('tr');

			while (Next && Next.hasClass('product-row'))
			{
				var product_index =  Next.getElement('.index').get('value');
				var category_input = new Element('input', {
					type: 'hidden',
					name: 'data[Quotation][' + product_index + '][Product][category]',
					value: category_name
				});

				Next.getElement('.index').destroy();

				category_input.inject(Next);
				Next = Next.getNext('tr');
			}
		});
	}

	var search = function()
	{
		if (PriceQuoteKeywords.get('value').trim())
		{
			request_search.send({data: {
				'keywords': PriceQuoteKeywords.get('value'),
				'currency_code': CurrencyCode
			}});
		}
	}

	var filter_search_results = function()
	{
		var results = ProductSearchResults.getElements('li');
		for (var i = 0; i < results.length; i++)
		{
			if (results[i].get('data-currency_code') === CurrencyCode)
				continue;

			results[i].destroy();
		}
	}

	var remove_product = function(event)
	{
		event.stop();

		var product_row = this.getParent('tr');

		liquid_table.removeDraggable(product_row.getElement('.draggable'));
		product_row.getElements('input, .product-trash').removeEvents();
		product_row.destroy();

		if (TableProducts.getElements('tbody tr:not(.category-row)').length === 0)
		{
			CurrencyCode = null;
		}
		calculate_total();
	}

	var validate_units = function()
	{
		var units = this.get('value').toFloat();
		if (units > 0)
		{
			return calculate_total();
		}

		this.set('value', 1);
	}

	var validate_discount = function()
	{
		var discount = this.get('value').toFloat();
		if (PRICE_QUOTE_MAX_DISCOUNT && discount > PRICE_QUOTE_MAX_DISCOUNT)
		{
			this.set('value', discount = PRICE_QUOTE_MAX_DISCOUNT);
		}

		if (discount >= 0 && discount <= 100)
		{
			return calculate_total();
		}

		this.set('value', 0);
	}

	var validate_price = function()
	{
		var price = this.get('value').replace(/[^0-9$.]/g, '').toFloat();

		if (PRICE_QUOTE_PRODUCT_COST)
		{
			var cost  = this.get('cost').replace(/[^0-9$.]/g, '').toFloat();

			if (price < cost)
			{
				price = cost;
				this.set('value', price.format({
					decimal: '.',
					group: ',',
					decimals: COMPANY_DECIMALS,
					prefix: ''
				}));
			}

			calculate_total();
		}
		else
		{
			if (price >= 0)
			{
				return calculate_total();
			}

			this.set('value', 0);
		}
	}

	tinyMCE.init({
		cache_suffix: '?v=5.0.3',
		mode: 'textareas',
		editor_selector: 'tinymce',
		mobile: {
			theme: 'silver',
			plugins: '',
			menubar: false,
			toolbar1: ''
		},
		menubar: false,
		toolbar_items_size: 'small',
		plugins: 'link anchor paste',
		paste_as_text: true,
		autoresize_min_height: 100,
		autoresize_max_height: 800,
		width: '100%',
		height: 100,
		convert_urls: false,
		statusbar: false,
		toolbar1: 'bold italic underline strikethrough link',
		language: Locale.getCurrent().name === 'es-ES' ? 'es_MX' : 'en_US'
	});

	var liquid_table = new LiquidTable(TableProducts);

	var smtp = new SwitchSMTP($('PriceQuoteUserSmtp'), {
		smtp: Auth.Smtp,
		sender: $('PriceQuoteSenderEmail'),
		label: $('SmtpSource')
	});

	// A replica from Company's method.
	var getFolderCompany = function(company_id)
	{
		return (+company_id + 10000).toString(16);
	}

	var calculate_profit = function(total_cost, total_price)
	{
		var profit = (parseFloat(total_price) - parseFloat(total_cost)).toFixed(COMPANY_DECIMALS);

		return {
			margin: profit > 0,
			profit: Math.abs(profit)
		}
	}

	var load_profit_popover = function(popoverHolder, Product)
	{
		var productDetailTemplate = [
			'<tr><td>' + Locale.get('Product.cost') + '</td><td class="text-right">{product_cost}</td></tr>',
			'<tr><td>' + Locale.get('Product.profit') + '</td><td class="text-right {profit_class}">',
			'<strong>{product_profit}</strong></td></tr>'
		].join('');

		var Profit = calculate_profit(Product.cost, Product.price);

		var details = productDetailTemplate.substitute({
			product_cost: parseFloat(Product.cost).format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS,
				prefix: ''
			}),
			profit_class: Profit.margin ? 'text-success' : 'text-danger',
			product_profit: (Profit.margin ? '+' : '-') + Profit.profit.format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS,
				prefix: ''
			}),
		});

		if (popoverHolder.hasClass('popover-loaded'))
		{
			// Since the Popover has some bugs on its event process (specially the destroy and hide),
			// I decided to get the element, replace the content and call the method setContent().
			//
			// Reference: https://github.com/twbs/bootstrap/blob/master/js/popover.js#L43
			var popover = jQuery(popoverHolder).data('bs.popover');

			popover.options.content = '<table style="width: 100%">' + details + '</table>';
			popover.setContent();
			popover.$tip.addClass(popover.options.placement);
		}
		else
		{
			jQuery(popoverHolder).popover({
				title: Locale.get('Default.details'),
				content: '<table style="width: 100%">' + details + '</table>',
				placement: 'top',
				container: 'body',
				trigger: 'hover',
				html: true,
				delay: 0
			}).popover();

			popoverHolder.addClass('popover-loaded');
		}
	};

	var load_product_image_modal = function(Product)
	{
		if (!Product.image)
		{
			return;
		}

		var productImageTemplate = [
			'<div id="ProductImageModal{product_id}" class="modal fade" tabindex="-1" role="dialog">',
			'<div class="modal-dialog" role="image"><div class="modal-content"><div class="modal-header">',
			'<button type="button" class="close" data-dismiss="modal" aria-label="Close">',
			'<span aria-hidden="true">&times;</span></button></div><div class="modal-body">',
			'<img class="img-responsive" style="width: 100%;" src="{image_url}" /></div>',
			'<div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">',
			Locale.get('Default.close') + '</button></div></div></div></div></div>'
		].join('');

		var ProductImageModal = productImageTemplate.substitute({
			product_id: Product.id,
			image_url: '/files/products/company_' + getFolderCompany(Product.company_id) + '/' + Product.image
		});

		jQuery('#ProductImageModalTrigger' + Product.id).parents('td.product-image-trigger').append(ProductImageModal);
		jQuery('#ProductImageModal' + Product.id).modal({
			show: false,
			keyboard: true
		});
	};

	/**
	 * Calculates subtotals, IEPS, taxes, withdrawals and grand total.
	 * Perform calculations taking data directly from the html generated by add_product()
	 * and after adding a product or modifing the price/discount/units of the product.
	 *
	 * @param {Object} event Event.
	 * @return {void}
	 */
	var calculate_total = function(event)
	{
		var grand_total     = 0;
		var grand_cost      = 0;
		var grand_withhold  = 0;
		var grand_tax       = 0;
		var grand_ieps      = 0;
		var tax             = PRICE_QUOTES_TAX;

		if (TaxSwitch)
		{
			if (PriceQuoteTax)
			{
				tax = PriceQuoteTax.get('value');
			}

			tax = TaxSwitch.getProperty('checked') ? tax : 0;
		}

		var product_rows = TableProducts.getElements('tbody tr:not(.category-row)');
		for (var i = 0; i < product_rows.length; i++)
		{
			var product_row = product_rows[i];
			var price       = product_row.getElement('.price').get('value');
			var units       = product_row.getElement('.units').get('value').toFloat();
			var discount    = product_row.getElement('.discount').get('value').toFloat();
			var withhold    = PRICE_QUOTES_TAX_WITHHOLD ? product_row.getElement('.withhold').get('value').toFloat() : 0;

			price = price.replace(/[^0-9$.]/g, '');
			price = price.toFloat();

			var total    = price * units * (1 - (discount / 100));
			var tax_base = 0;

			if (PRICE_QUOTE_IEPS_ENABLED)
			{
				var ieps_product_total = 0;
				var ieps_type = product_row.getElement('.product_ieps_type').get('value');
				var ieps_value = product_row.getElement('.product_ieps_value').get('value');

				if (ieps_type == IEPS_RATE)
				{
					ieps_product_total = (total * ieps_value) / 100;
				}
				if (ieps_type == IEPS_FEE)
				{
					ieps_product_total = ieps_value * units;
				}

				total += ieps_product_total;

				product_row.getElement('.product_ieps').set('value', ieps_product_total.format({
					decimal: '.',
					group: ',',
					decimals: COMPANY_DECIMALS,
					prefix: ''
				}));

				var product_total_with_ieps = total + ieps_product_total;
				product_row.getElement('.total').set('text', product_total_with_ieps.format({
					decimal: '.',
					group: ',',
					decimals: COMPANY_DECIMALS,
					prefix: ''
				}));

				grand_ieps += ieps_product_total;
			}

			// Make sure only products marked for tax object calculate IVA
			var is_sat_tax_object = product_row.getElement('.sat_tax_object').get('value');
			if (is_sat_tax_object)
			{
				tax_base += total;
			}

			grand_tax += tax_base * (tax / 100);
			grand_withhold += tax_base * (withhold / 100);

			product_row.getElement('.total').set('text', total.format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS,
				prefix: ''
			}));

			if (PRICE_QUOTE_PRODUCT_COST)
			{
				var cost = product_row.getElement('.cost').get('value').toFloat();

				load_profit_popover(product_row.getElement('.total'), {
					cost: cost * units,
					price: total
				});

				grand_cost += cost * units;
			}

			grand_total += total;
		}
		var posfix = ' <small class="text-muted">' + (CurrencyCode || '') + '</small>';

		$('PriceQuoteTaxTotal').set('value', grand_tax);

		if (PRICE_QUOTE_PRODUCT_COST)
		{
			load_profit_popover(Total, {
				cost: grand_cost,
				price: grand_total
			});
		}

		Subtotal.set('html', grand_total.format({
			decimal: '.',
			group: ',',
			decimals: COMPANY_DECIMALS,
			prefix: ''
		}) + posfix);

		Tax.set('html', grand_tax.format({
			decimal: '.',
			group: ',',
			decimals: COMPANY_DECIMALS,
			prefix: ''
		}) + posfix);

		if (Withhold)
		{
			Withhold.set('html', grand_withhold.format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS,
				prefix: ''
			}) + posfix);
		}

		if (IEPSTotal)
		{
			IEPSTotal.set('html', grand_ieps.format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS,
				prefix: ''
			}) + posfix);
			$('PriceQuoteIepsGrandTotal').set('value', grand_ieps);
		}

		grand_total = grand_total + grand_tax - grand_withhold;

		Total.set('html', grand_total.format({
			decimal: '.',
			group: ',',
			decimals: COMPANY_DECIMALS,
			prefix: ''
		}) + posfix);

		if ($('PricequoteWarning'))
		{
			$('PricequoteWarning').destroy();
		}

		if (PRICE_QUOTE_CONEKTA_PAYMENTS === true && PRICE_QUOTE_CONEKTA_LIMIT <= grand_total)
		{
			new Element('small', {
				'id': 'PricequoteWarning',
				'class': 'pricequote-warning text-danger text-left visible-xs-block visible-sm-inline-block ' +
				'visible-sm-inline-block visible-md-inline-block visible-lg-inline-block',
				'style': 'font-size: 11px;',
				'text': Locale.get('Default.pricequoteWarning').substitute({
					conekta_limit: PRICE_QUOTE_CONEKTA_LIMIT.format({
						decimal: '.',
						group: ',',
						decimals: COMPANY_DECIMALS,
						prefix: ''
					})
				}),
			}).inject(Total.getParent('div'));
		}
	};

	/**
	 * Adds a product to the UI, parsing the Product object into an html table row
	 * with required inputs to save it.
	 *
	 * @param {Object} Product Retrieved product.
	 * @return {void}
	 */
	var add_product = function(Product)
	{
		var should_filter_search_results = !CurrencyCode;

		count++;
		CurrencyCode = Product.currency_code;

		if (should_filter_search_results)
		{
			filter_search_results();
		}

		var new_product = TemplateToClone.clone();
		var price = parseFloat(Product.price).toFixed(COMPANY_DECIMALS);
		var cost  = parseFloat(Product.cost ? Product.cost : Product.price).toFixed(COMPANY_DECIMALS);

		// Only remove taxes from products marked as non-tax-object
		var is_sat_tax_object = (Product.sat_tax_object === '01' || Product.sat_tax_object === '03') ? '' : 1;

		new_product.addClass('product-row');

		new_product.getElement('.product_id').setProperties({
			'value': Product.id,
			'name': 'data[Quotation][' + count + '][Product][product_id]'
		});
		new_product.getElement('.image').setProperties({
			'value': Product.image,
			'name': 'data[Quotation][' + count + '][Product][image]'
		});
		new_product.getElement('.product-image').setProperties({
			'id': 'ProductImageModalTrigger' + Product.id,
			'class': Product.image ? 'product-image glyphicon glyphicon-picture' : 'product-image hidden',
			'data-toggle': 'modal',
			'data-target': '#ProductImageModal' + Product.id,
			'style': Product.image ? 'cursor: pointer' : ''
		});
		new_product.getElement('.name').setProperties({
			'value': Product.name,
			'name': 'data[Quotation][' + count + '][Product][name]',
			'readonly': (VENDORS_CAN_EDIT_PRODUCT ? null : 'readonly')
		});
		new_product.getElement('.description').setProperties({
			'value': Product.description,
			'name': 'data[Quotation][' + count + '][Product][description]',
			'readonly': (VENDORS_CAN_EDIT_PRODUCT ? null : 'readonly')
		});
		new_product.getElement('.index').setProperties({
			'name': 'data[Quotation][' + count + '][Product][index]',
			'value': count
		});
		new_product.getElement('.sat_tax_object').setProperties({
			'name': 'data[Quotation][' + count + '][Product][index]',
			'value': is_sat_tax_object
		});

		new_product.getElement('.currency_symbol').set('html', Product.currency_symbol);
		new_product.getElement('.price').setProperties({
			'id': 'Product' + Product.id,
			'value': price,
			'cost': cost,
			'name': 'data[Quotation][' + count + '][Product][price]',
			'data-original-title': Date.parse(Product.price_date).format(Locale.get('Date.naturalDateTime')),
			'data-cleave': 'currency',
			'readonly': (PRICE_QUOTE_EDIT_PRICE ? null : 'readonly'),
			'min': (PRICE_QUOTE_PRODUCT_COST ? cost : 0)
		}).addEvents({
			'change': validate_price,
			'blur': validate_price
		});

		var product_discount_predefined = parseFloat(Product.discount);

		// If global discount is available and has a greater value than zero.
		if (GlobalDiscount && GlobalDiscount.value > 0 && PRICE_QUOTE_EDIT_DISCOUNT)
		{
			product_discount_predefined = parseFloat(GlobalDiscount.value);
		}

		// If max discount is set and is less than discount predefined.
		if (PRICE_QUOTE_MAX_DISCOUNT && PRICE_QUOTE_MAX_DISCOUNT < product_discount_predefined)
		{
			product_discount_predefined = parseFloat(PRICE_QUOTE_MAX_DISCOUNT);
		}

		Product.discount = parseFloat(product_discount_predefined);
		var discount_options = {
			'name': 'data[Quotation][' + count + '][Product][discount]',
			'value': Product.discount > 0 ? Product.discount : 0
		};
		if (!PRICE_QUOTE_EDIT_DISCOUNT)
		{
			discount_options.readonly = true;
		}

		new_product.getElement('.discount').setProperties(discount_options).addEvent('change', validate_discount);

		if (PRICE_QUOTES_TAX_WITHHOLD)
		{
			tax_withhold = Product.tax_withholding != undefined ? parseFloat(Product.tax_withholding) : 0;
			new_product.getElement('.withhold').setProperties({
				'name': 'data[Quotation][' + count + '][Product][tax_withholding]',
				'value': tax_withhold
			}).addEvent('change', calculate_total);
		}
		if (PRICE_QUOTE_PRODUCT_SKU)
		{
			new_product.getElement('.sku').set('html', Product.sku);
		}

		Product.units = parseFloat(Product.units);
		new_product.getElement('.units').setProperties({
			'name': 'data[Quotation][' + count + '][Product][units]',
			'value': Product.units >= 1 ? Product.units : 1
		}).addEvent('change', validate_units);

		new_product.getElement('.product-trash').addEvent('click', remove_product);
		new_product.inject(TableProducts.getElement('tbody'), 'bottom');

		var rows = TableProducts.getElements('tbody tr:not(.category-row)');
		if (rows.length === 1 && PriceQuoteTitle.get('value') === '')
		{
			PriceQuoteTitle.set('value', Product.name);
		}
		if (rows.length === 1 && PriceQuoteMailSubject.get('value') === '' && ToggleEmailQuotation.hasClass('send'))
		{
			PriceQuoteMailSubject.set('value', Product.name);
		}

		liquid_table.addDraggable(new_product.getElement('.draggable'));

		if (PRICE_QUOTE_IEPS_ENABLED)
		{
			product_ieps = calculate_product_ieps(Product);

			new_product.getElement('.product_ieps').set('value', parseFloat(product_ieps).format({
				decimal: '.',
				group: ',',
				decimals: COMPANY_DECIMALS,
				prefix: ''
			}));

			new_product.getElement('.product_ieps_type').set('value', Product.ieps_type_id);
			new_product.getElement('.product_ieps_value').set('value', Product.ieps_value);
		}

		if (PRICE_QUOTE_PRODUCT_COST)
		{
			new_product.getElement('.cost').setProperties({
				'text': parseFloat(cost).format({
					decimal: '.',
					group: ',',
					decimals: COMPANY_DECIMALS,
					prefix: Product.currency_symbol
				}),
				'value': cost
			});
		}
		if (PRICE_QUOTE_PRODUCT_STOCK)
		{
			new_product.getElement('.stock').setProperties({
				'text': parseInt(Product.stock),
				'value': Product.stock
			});
		}

		jQuery('.enable-tooltip').tooltip();

		load_product_image_modal(Product);
		calculate_total();
		attachCleaveFunctionality(new_product.getElement('.price'));
	};

	var preload_products = function preload_products()
	{
		if (IS_PROJECT_PRICE_QUOTE)
		{
			var products_by_category = {};
			products_preloaded.each(function(product){
				var category_key = product.category;
				if (!(category_key in products_by_category))
				{
					products_by_category[product.category] = [];
				}
				products_by_category[product.category].push(product);
			});

			var category_keys = Object.keys(products_by_category);
			category_keys.each(function(category_key){
				add_category(category_key);
				products_by_category[category_key].each(function(product){
					add_product(product);
				});
			});
		}
		else
		{
			for (i = 0; i < products_preloaded.length; i++)
			{
				add_product(products_preloaded[i]);
			}
		}
	};

	var calculate_product_ieps = function(Product)
	{
		var ieps = 0;

		if (!Product.ieps_type_id && !Product.ieps_value)
		{
			return 0;
		}

		if (Product.ieps_type_id == IEPS_RATE)
		{
			ieps = (Product.ieps_value * 100) / Product.price;
		}
		else if (Product.ieps_type_id == IEPS_FEE)
		{
			ieps = (Product.ieps_value);
		}

		return ieps;
	}

	preload_products();
});
