window.addEvent('domready', function(){

	if (!Auth.User)
		return;

	var GlobalSearchForm = $('GlobalSearchForm');
	var GlobalSearchInput = $('GlobalSearchInput');
	var GlobalSearchResults = $('GlobalSearchResults');
	var GlobalSubmitSearch = $('GlobalSubmitSearch');

	if (GlobalSearchForm && GlobalSearchInput && GlobalSearchResults && GlobalSubmitSearch)
	{
		var auto_search_time = 1000;
		var timer;

		var GlobalSubmitSearchIcon = GlobalSubmitSearch.getElement('span');
		var jQueryGlobalSearchInput = jQuery('#GlobalSearchInput');

		$('GlobalSearch').addEvent('click', function(event)
		{
			if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
			{
				event.stop();
			}

			$$('.hidden-search').addClass('hide');
			GlobalSearchForm.removeClass('hide');
			GlobalSearchInput.focus();
		});

		var show_normal_menu = function()
		{
			$$('.hidden-search').removeClass('hide');
			GlobalSearchForm.addClass('hide');

			if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
			{
				GlobalSearchForm.removeClass('hide');
			}
		}

		GlobalSearchInput.addEvent('keyup', function(event){
			var keyword = GlobalSearchInput.get('value');

			if (event.key == 'esc')
			{
				show_normal_menu();
			}

			if (keyword === '')
			{
				GlobalSearchInput.getParent().removeClass('open');
			}
		});

		jQueryGlobalSearchInput.bind('keyup', function(event){
			if (event.keyCode != '13')
			{
				clearTimeout(timer);
				timer = setTimeout(search, auto_search_time)
			}
		});

		$$('body').addEvent('click', function(event) {

			if (event.target != GlobalSearchInput && event.target != GlobalSubmitSearch && event.target != GlobalSubmitSearchIcon)
				show_normal_menu();

			if (event.target == GlobalSubmitSearchIcon)
				search();
		});

		var search = function()
		{
			if (GlobalSearchInput.get('value').trim() !== '')
			{
				new Request({
					url: '/search/',
					data: GlobalSearchForm,
					onRequest: function()
					{
						GlobalSearchInput.getParent().removeClass('open');
						GlobalSubmitSearchIcon.addClass('loader');
					},
					onComplete: function()
					{
						GlobalSubmitSearchIcon.removeClass('loader');
					},
					onCancel: function()
					{
					},
					onSuccess: function(responseText, responseXML)
					{
						GlobalSearchResults.set('html', responseText);
						jQueryGlobalSearchInput.dropdown('toggle');
						GlobalSearchResults.removeClass('hide');
					}
				}).send();
			}
		}
	}
});
