Fx.Counter = new Class({
	Extends: Fx,
	options: {
		decimals: 2,
		duration: 500,
		transition: 'quad:out',
		format: 'integer',
		unit_code: null
	},

	initialize: function(element, options)
	{
		this.parent(options);
		this.element = document.id(element);
	},

	set: function(now)
	{
		if (this.options.format === 'currency')
		{
			var prefix = this.options.currency_symbol;
			prefix += prefix === 'UDI' ? ' ' : '';
			this.element.set('text', now.round().format({prefix: prefix, decimals: this.options.decimals}));
		}
		else if (this.options.format === 'percentage')
			this.element.set('text', now.formatPercentage(0));
		else if (this.options.format === 'units')
			this.element.set('text', now.round().format({suffix: this.options.unit_code}));
		else
			this.element.set('text', now.round());

		return this;
	},

	start: function(from, to)
	{
		if (this.isRunning())
			this.stop();

		this.parent(isNaN(from) ? 0 : from, isNaN(to) ? 0 : to);
	}
});
