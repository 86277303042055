window.addEvent('domready', function(){
	/* Modal: Nueva Oportunidad Global
	--------------------------------------------- */
	var initialize_select_client = function(CreatePriceQuote)
	{
		var PriceQuoteClientId = CreatePriceQuote.getElement('#PriceQuoteClientId');
		var PriceQuoteClientProjectId = CreatePriceQuote.getElement('#PriceQuoteClientProjectId');

		if (typeof CLIENT !== 'undefined')
		{
			PriceQuoteClientId.value = CLIENT.id;
		}
		var ClientSearch = new DynamicSearch({
			target: PriceQuoteClientId,
			style: 'width: 100%',
			searchURL: '/clients/search.json',
			autoselectURL: '/clients/search/autoselect:true.json',
			templates: {
				value: '{Client.id}',
				label: [
					'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
					'<span class="label label-{Client.label}">{Client.type}</span></small>',
					'<span style="margin-right: 120px">{Client.name}</span>',
				].join(''),
				option: [
					'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
					'<span class="label label-{Client.label}">{Client.type}</span><br>{Client.phone}</small>',
					'<span style="margin-right: 120px">{Client.name}</span><br>',
					'<small class="text-muted" style="margin: 5px 0 120px 0; padding: 0;">{Client.email}</small>'
				].join(''),
			},
			label: {empty: Locale.get('Default.searchClientOrProspect')}
		});

		var SubmitButton = CreatePriceQuote.getParent('.modal').getElement('#CreatePriceQuoteForClient');
		if (SubmitButton)
		{
			var ProjectSearch = null;
			var create_price_quote_action = '/price_quotes/create/client:';
			var project_search_options = {
				target: PriceQuoteClientProjectId,
				style: 'width: 100%',
				searchURL: '/projects/get_by_client.json',
				templates: {
					value: '{Project.id}',
					label: [
						'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
						'<span class="label label-default label-outline">{ProjectType.name}</span></small>',
						'<span style="margin-right: 120px">{Project.name}</span>',
					].join(''),
					option: [
						'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
						'<span class="label label-default label-outline">{ProjectType.name}</span></small>',
						'<span style="margin-right: 120px">{Project.name}</span><br>',
						'<small class="text-muted" style="margin: 5px 0 120px 0; padding: 0;">{Project.projection}</small>'
					].join(''),
				},
				label: {empty: Locale.get('PriceQuote.selectProject')}
			};

			ClientSearch.onChange(function(client_id){
				if (!client_id)
				{
					SubmitButton.addProperty('disable').addClass('disabled');

					return;
				}

				SubmitButton.removeProperty('disable').removeClass('disabled');

				if (!Auth.Plan.special)
				{
					if (ProjectSearch === null)
					{
						ProjectSearch = new DynamicSearch(project_search_options);
					}
					ProjectSearch.options.searchURL = '/projects/get_by_client/' + client_id + '.json';
				}
			});

			if (ClientSearch.selected())
			{
				SubmitButton.removeProperty('disable').removeClass('disabled');

				project_search_options.searchURL = '/projects/get_by_client/' + ClientSearch.selected() + '.json';
				ProjectSearch = new DynamicSearch(project_search_options);
			}

			SubmitButton.addEvent('click', function(){
				var create_project_url = '/price_quotes/create/client:' + ClientSearch.selected();
				create_project_url += !Auth.Plan.special ? '/project:' + ProjectSearch.selected() : '';

				window.location = create_project_url;
			});
		}
	}

	var GlobalNewPriceQuoteModal = $('GlobalNewPriceQuote');
	if (GlobalNewPriceQuoteModal)
	{
		var GlobalNewPriceQuoteModalContent = GlobalNewPriceQuoteModal.getElement('.modal-content');
		var default_content = GlobalNewPriceQuoteModalContent.get('html');

		jQuery(GlobalNewPriceQuoteModal).on('shown.bs.modal', function(e) {
			var CreatePriceQuote = $('CreatePriceQuote');

			window.requestAnimationFrame(function(){
				GlobalNewPriceQuoteModal.getElement('.loader').destroy();
				if (CreatePriceQuote)
				{
					initialize_select_client(CreatePriceQuote);
				}
			});
		}).on('hidden.bs.modal', function(e) {
			GlobalNewPriceQuoteModalContent.getChildren().destroy();
			GlobalNewPriceQuoteModalContent.set('html', default_content);
		});
	}
});
