var SelectDropdown = new Class({
	Implements: Events,
	group: null,
	display: null,
	values: [],

	initialize: function(group)
	{
		this.group = typeof group === 'object' ? group : $(group);

		if (!this.group)
			return null;

		this.display = this.group.getElement('.dropdown-display');
		this.values = this.group.getElements('.dropdown-menu li a');

		for (var n = 0, l = this.values.length; n < l; n++)
		{
			this.values[n].addEvent('click', this.updateValue.bind(this, this.values[n]));
		}
	},

	updateValue: function(event)
	{
		this.setSelected(arguments[0]);
	},

	clearSelected: function()
	{
		for (var n = 0, l = this.values.length; n < l; n++)
		{
			this.values[n].removeClass('selected').removeClass('strong');
		}
	},

	setSelected: function(element)
	{
		this.clearSelected();
		element.addClass('selected').addClass('strong');

		if (this.display)
			this.display.set('text', element.get('text'));

		this.fireEvent('change');
	},

	set: function(property, value)
	{
		if (property === 'value')
		{
			for (var n = 0, l = this.values.length; n < l; n++)
			{
				if (this.values[n].get('data-value') == value)
					this.setSelected(this.values[n]);
			}
		}

		return null;
	},

	get: function(property)
	{
		if (property === 'value')
		{
			for (var n = 0, l = this.values.length; n < l; n++)
			{
				if (this.values[n].hasClass('selected'))
					return this.values[n].get('data-value');
			}
		}

		return '';
	}
});
