window.addEvent('domready', function() {
	$$('form').each(function(form){
		var select_countries = form.getElement('select.country_id');
		var select_states    = form.getElement('select.state_id');
		var select_cities    = form.getElement('select.city_id');

		if (select_countries && select_states)
		{
			select_countries.addEvent('change', function(){
				updateStates(select_countries, select_states);
			});
		}
		if (select_states && select_cities)
		{
			select_states.addEvent('change', function(){
				updateCities(select_states, select_cities);
			});
		}
	});

	function updateStates(select_countries, select_states)
	{
		if (select_countries && select_states)
		{
			new Request({
				method: 'post',
				url: '/countries/get_states',
				onRequest: function()
				{
					select_states.set('disabled', true);
				},
				onSuccess: function(response)
				{
					if (response != 'NO STATES')
					{
						select_states.set('html', response);
						select_states.set('disabled', false);
					}
					else
					{
						select_states.set('html', '<option value="">' + Locale.get('Default.noStates') + '</option>');
						select_states.set('disabled', true);
					}
					select_states.fireEvent('updated');
				}
			}).send('data[country_id]=' + select_countries.get('value'));
		}
	}

	function updateCities(select_states, select_cities){
		if (select_states && select_cities){
			new Request({
				method: 'post',
				url: '/states/get_cities',
				onRequest: function()
				{
					select_cities.set('disabled', true);
				},
				onSuccess: function(response)
				{
					if (response != 'NO CITIES')
					{
						select_cities.set('html', response);
						select_cities.set('disabled', false);
					}
					else
					{
						select_cities.set('html', '<option value="">' + Locale.get('Default.noCities') + '</option>');
						select_cities.set('disabled', true);
					}

					select_cities.fireEvent('updated');
				}
			}).send('data[state_id]=' + select_states.get('value'));
		}
	}

	if ($('WorklistForm'))
	{
		var  Selects = $$('select.country_id, select.state_id, select.city_id');
		if (Selects.length !== 0){
			Selects.each(function(select){
				var ds_select = new DynamicSearch({
					target: select,
					style: 'width: 100%',
					label: {
						empty: select.getProperty('placeholder') || ' — ',
						plural: select.getProperty('data-plural') || Locale.get('Label.elements'),
						singular: select.getProperty('data-singular') || Locale.get('Label.element'),
					},
					multiple: !!select.getProperty('multiple'),
					search: {
						enable: select.getElements('option').length > 10,
						min_chars: 1
					}
				});

				select.addEvent('updated', function(){
					ds_select.setOptions({
						search: {
							enable: select.getElements('option').length > 10,
							min_chars: 1
						}
					});

					ds_select.rebuild();
				})
			});
		}

		$('WorklistForm').addEvent('change', function(){
			var select_countries = $(this).getElement('select.country_id');
			var select_states = $(this).getElement('select.state_id');
			var select_cities = $(this).getElement('select.city_id');

			if (select_countries && select_states)
			{
				select_countries.addEvent('change', function(){
					updateStates(select_countries, select_states);
				});
			}
			if (select_states && select_cities)
			{
				select_states.addEvent('change', function(){
					updateCities(select_states, select_cities);
				});
			}
		});
	}
});
