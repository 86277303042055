var DynamicSearch = new Class({
	Implements: [Options],
	data: null,
	input: null,
	parent: null,
	selected_values: [],
	current_item: null,
	options: {
		autoselectURL: null,
		class: '',
		data: null,
		label: {
			empty: null,
			singular: null,
			plural: null,
		},
		multiple: false,
		search: {
			enable: true,
			min_chars: 3,
		},
		searchURL: null,
		style: '',
		target: null,
		templates: {
			label:  '{Element.name}',
			option: '{Element.name}',
			search: '{Element.name}',
			value:  '{Element.id}',
		},
	},
	initialize: function(options)
	{
		this.options.label.empty    = Locale.get('Label.select');
		this.options.label.singular = Locale.get('Label.element');
		this.options.label.plural   = Locale.get('Label.elements');

		this.setOptions(options);

		var input = this.options.target;
		if (typeof input === 'string')
			input = $(input);
		if (!input)
			return false;

		this.input  = input;
		this.parent = input.getParent();
		this.form   = input.getParent('form');
		this.input.addClass('dynamic-search hidden');

		if (this.input.nodeName === 'SELECT')
		{
			this.input.set('multiple', this.options.multiple);

			this.options.data = this.input.getElements('option').map(function(el){
				if (!el.value && !el.text)
				{
					return false;
				}

				return {
					Element: {
						id: el.value,
						name: (el.text ? el.text : this.options.label.empty)
					}
				};
			}.bind(this)).filter(Boolean);

			this.selected_values = this.input.getElements('option').map(function(el){
				return el.selected ? el.value : false;
			}).filter(Boolean);
		}
		else
		{
			this.options.multiple = false;
			this.selected_values.push(this.input.value);
		}

		if (this.options.data)
		{
			var data_hash = [];
			var template  = this.options.templates.search;
			var self      = this;
			this.options.data.forEach(function(item, index){
				var hash = template.substitute(
					self.flatMap(item, template)
				).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' ').join('|');
				data_hash.push(hash);
			});

			this.data_hash = data_hash;
		}

		if (!this.options.data && !this.options.searchURL)
		{
			throw new Error('No data and URL given.');
		}

		this.render();

		if (this.options.data)
		{
			this.renderList(this.options.data);
		}

		this.input.addEvent('changed', function(){
			var select = this.options.data.filter(function(item, index){
				return (self.flatMap(item, self.options.templates.value))['Element.id'] == self.input.value ? index.toString() : false;
			});

			if (select.length > 0)
			{
				this.current_item = select[0];
			}

			this.renderLabel();
		}.bind(this));
	},
	render: function()
	{
		if (this.input.hasAttribute('required'))
		{
			this.input.removeAttribute('required');
			this.form.addEvent('submit', function(event){
				if (!this.input.value)
				{
					event.stop();
				}

				DropdownToggle.toggleClass('has-error', !this.input.value);
			}.bind(this));
		}

		var dynamic_search_style = ' dynamic-search-rounded ';
		var input_group_addon = this.input.getParent('.input-group > .input-group-addon');
		if (input_group_addon)
		{
			dynamic_search_style = ' dynamic-search-squared ';

			dynamic_search_style += input_group_addon.getLast('span') ? ' right ' : ' left ';
		}

		var DropdownContainer = new Element('div', {
			id: this.options.id,
			style: this.options.style,
			class: 'dynamic-search btn-group bootstrap-select ' + this.options.class + dynamic_search_style,
		});
		var DropdownToggle = new Element('button', {
			id: (new Date()).getMilliseconds(),
			class: 'btn dropdown-toggle btn-default',
			'data-toggle': 'dropdown',
			role: 'button',
			style: this.input.get('style')
		});
		var DropdownToggleSpan = new Element('span', {
			class: 'filter-option pull-left',
			html: this.options.label.empty
		});
		var DropdownToggleSpanCaret = new Element('span', {
			class: 'caret'
		});
		var DropdownToggleSpanCaretBs = new Element('span', {
			class: 'bs-caret'
		});
		var DropdownMenuContainer = new Element('div', {
			class: 'dropdown-menu',
			role: 'combobox'
		});
		var DropdownMenuSearchBox = new Element('div', {class: 'bs-searchbox'});
		var DropdownMenuSearchBoxInput = new Element('input', {
			type: 'search',
			class: 'form-control ' + (this.options.search.enable ? '' : 'hidden'),
			autocomplete: 'off',
			role: 'textbox',
			'aria-label': 'Search',
			tabindex: -1,
			name: 'ClientSearch'
		});
		var DropdownMenuInner = new Element('ul', {
			class: 'dropdown-menu inner',
			role: 'listbox',
			'aria-expanded': 'true',
			style: [
				'max-height: 300px;',
				'overflow-y: auto;'
			].join('')
		});
		var DropdownMenuInnerListItem = new Element('li', {
			'data-original-index': 0,
			'tabindex': -1
		});
		var DropdownMenuInnerListItemAnchor = new Element('a', {
			tabindex: '0',
			role: 'option',
			'data-tokens': 'null',
			'aria-disabled': 'false',
			'aria-selected': 'false'
		});
		var DropdownMenuInnerListItemAnchorSpan = new Element('span', {
			class: 'text'
		});

		DropdownToggleSpanCaret.adopt(DropdownToggleSpanCaretBs);
		DropdownContainer.adopt(DropdownToggle.adopt(DropdownToggleSpan.adopt(DropdownToggleSpanCaret)));
		DropdownMenuContainer.adopt(DropdownMenuSearchBox.adopt(DropdownMenuSearchBoxInput));
		DropdownContainer.adopt(DropdownMenuContainer.adopt(DropdownMenuInner));

		var self = this;

		self.DropdownContainer                   = DropdownContainer;
		self.DropdownMenuSearchBoxInput          = DropdownMenuSearchBoxInput;
		self.DropdownToggle                      = DropdownToggle;
		self.DropdownToggleSpan                  = DropdownToggleSpan;
		self.DropdownToggleSpanCaret             = DropdownToggleSpanCaret;
		self.DropdownMenuInner                   = DropdownMenuInner;
		self.DropdownMenuInnerListItem           = DropdownMenuInnerListItem;
		self.DropdownMenuInnerListItemAnchor     = DropdownMenuInnerListItemAnchor;
		self.DropdownMenuInnerListItemAnchorSpan = DropdownMenuInnerListItemAnchorSpan;

		jQuery(DropdownContainer).on('shown.bs.dropdown', function(){
			self.DropdownMenuSearchBoxInput.focus();
		});

		self.DropdownContainer.inject(self.parent, DropdownContainer.hasClass('left') ? 'top' : 'bottom');
		DropdownMenuSearchBoxInput.addEvents({
			keydown: function(event)
			{
				var UP              = 38;
				var DOWN            = 40;
				var ENTER           = 13;
				var list            = this.getParent('.dropdown-menu').getElement('ul');
				var selected        = list.getElement('.selected.active');
				var last_event_code = this.retrieve('last_event_code');

				if (event.code === ENTER)
				{
					event.stop();

					if (!self.options.multiple && [UP, DOWN].includes(last_event_code) && selected)
					{
						selected.fireEvent('select');
					}
					else
					{
						this.fireEvent('search');
					}

					return;
				}
				this.store('last_event_code', event.code);

				if (!list.getChildren('.searchbox-list-item').length || self.options.multiple)
				{
					return;
				}

				if (event.code === UP)
				{
					selected = (selected && selected.getPrevious() || list.getLast('.searchbox-list-item'));
					selected.addClass('selected active').getSiblings().removeClass('selected active');
					selected.scrollIntoView();
				}
				if (event.code === DOWN)
				{
					selected = (selected && selected.getNext() || list.getFirst('.searchbox-list-item'));
					selected.addClass('selected active').getSiblings().removeClass('selected active');
					selected.scrollIntoView();
				}
			},
			search: function()
			{
				if (!this.value.length)
				{
					return self.renderList(self.options.data);
				}

				var regex = new RegExp('\\w{' + self.options.search.min_chars + ',}');

				return regex.test(this.get('value')) && self.search(this.get('value'));
			}
		});

		if (this.input.get('value'))
		{
			if (this.options.autoselectURL)
			{
				new Request({
					url: this.options.autoselectURL,
					data: {id: this.input.get('value')},
					onSuccess: function(response){
						response = JSON.decode(response);

						if (!response.response || Object.keys(response.response).length === 0)
						{
							return;
						}

						self.current_item = response.response;
						self.renderLabel();
					}
				}).send();
			}
			else
			{
				var select = this.options.data.filter(function(item, index){
					return (self.flatMap(item, self.options.templates.value))['Element.id'] == self.input.value ? index.toString() : false;
				});

				if (select.length > 0)
				{
					this.current_item = select[0];
				}

				this.renderLabel();
			}
		}
	},
	search: function(search)
	{
		if (typeof search !== 'string')
		{
			return false;
		}

		this.clearList();

		var self = this;
		if (this.options.searchURL)
		{
			new Request({
				url: this.options.searchURL,
				data: {keywords: search},
				onSuccess: function(res){
					self.clearList();
					self.renderList((JSON.decode(res)).response);
				}
			}).send();
		}
		else
		{
			var search_results = [];

			search = (search.toLowerCase()).normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' ').join('|');
			this.data_hash.forEach(function(hash, index){
				if (hash.indexOf(search) > -1)
				{
					search_results.push(self.options.data[index]);
				}
			});

			this.renderList(search_results);
		}
	},
	setValues: function()
	{
		var selected_values = this.selected_values;

		if (this.input.nodeName === 'SELECT')
		{
			this.input.getElements('option').forEach(function(option, index){
				if (selected_values.includes(option.value) || selected_values.includes(parseInt(option.value)))
				{
					option.setProperty('selected', true);
				}
				else
				{
					option.removeProperty('selected');
				}
			});
		}
		else
		{
			this.input.set('value', Array.from(selected_values).pop());
		}

		if (!selected_values.length)
		{
			this.DropdownToggleSpan.setProperty('html', this.options.label.empty).adopt(this.DropdownToggleSpanCaret);
		}
	},
	clearList: function()
	{
		this.DropdownMenuInner.getChildren().destroy();
	},
	renderList: function(list)
	{
		this.clearList();

		var self = this;

		if (typeof list === 'undefined' || Object.keys(list).length === 0)
		{
			self.DropdownMenuInner.empty().adopt(
				self.DropdownMenuInnerListItem.clone().adopt(
					self.DropdownMenuInnerListItemAnchor.clone().adopt(
						self.DropdownMenuInnerListItemAnchorSpan.clone().setProperties({
							html: Locale.get('System.noResults').substitute({
								search: self.DropdownMenuSearchBoxInput.get('value')
							}),
							'data-original-index': 0,
							class: 'searchbox-list-item'
						})
					)
				)
			);

			self.DropdownMenuInner.setStyle('display', 'block');

			return;
		}

		list.forEach(function(item, item_index){
			var option_template  = self.options.templates.option;
			var option_html      = option_template.substitute(self.flatMap(item, option_template));
			var value_template   = self.options.templates.value;
			var item_value       = value_template.substitute(self.flatMap(item, value_template));
			var item_class       = '';

			if (self.selected_values.includes(item_value) || self.selected_values.includes(parseInt(item_value)))
			{
				item_class = 'selected active';
			}

			var DropdownMenuInnerListItem = self.DropdownMenuInnerListItem.clone();

			DropdownMenuInnerListItem.setProperties({
				'data-value': item_value,
				'data-original-index': item_index,
				'class': 'searchbox-list-item ' + item_class
			}).addEvents({
				click: function(event){
					if (self.options.multiple)
					{
						event.stop();
					}

					if (this.hasClass('selected'))
					{
						this.fireEvent('unselect');
					}
					else
					{
						this.fireEvent('select');
					}
				},
				select: function()
				{
					if (!self.options.multiple)
					{
						this.getParent().getChildren().removeClass('selected active');
					}

					var selected_value = this.get('data-value');
					if (self.options.multiple && !self.selected_values.includes(selected_value))
					{
						self.selected_values.push(selected_value);
					}
					else
					{
						self.selected_values = [selected_value];
					}
					if (self.options.searchURL)
					{
						this.getParent().getChildren().destroy();
						self.DropdownMenuSearchBoxInput.setProperty('value', null);
					}

					self.setValues();
					self.current_item = item;
					self.input.fireEvent('change');
					this.addClass('selected active');

					if (!self.options.multiple)
					{
						jQuery(self.DropdownToggle).dropdown('toggle');
					}

					self.renderLabel();
				},
				unselect: function(){
					if (self.options.multiple)
					{
						var unselect_value = this.get('data-value');
						self.selected_values = self.selected_values.filter(function(value){
							return unselect_value != value;
						});
					}
					else
					{
						self.selected_values = [];
					}
					self.current_item = null;

					this.removeClass('selected active');
					self.setValues();
					self.renderLabel();
				}
			}).adopt(
				self.DropdownMenuInnerListItemAnchor.clone().setProperties({
					'aria-selected': 'true'
				}).adopt(self.DropdownMenuInnerListItemAnchorSpan.clone().setProperties({
					html: option_html,
					style: 'width: 100%;'
				}))
			);

			self.DropdownMenuInner.adopt(DropdownMenuInnerListItem);
		});

		self.DropdownMenuInner.setStyle('display', 'block');
	},
	renderLabel: function(){
		var DropdownLabel = this.DropdownContainer.getElement('.filter-option');
		var DropdownToggleSpanCaret = this.DropdownToggleSpanCaret.clone();
		var label_html = this.options.label.empty;

		if (this.options.multiple && this.selected_values.length > 0)
		{
			var singular = this.options.label.singular;
			var plural   = this.options.label.plural;
			var count    = this.selected_values.length;
			var elements = (count === 1) ? singular : plural;
			label_html   = count + ' ' + elements;
		}
		else if (this.current_item)
		{
			var label_template = this.options.templates.label;
			label_html         = label_template.substitute(this.flatMap(this.current_item, label_template));
		}

		DropdownLabel.setProperty('html', label_html).adopt(DropdownToggleSpanCaret);
	},
	selected: function()
	{
		return this.input.value;
	},
	onChange: function(callback){
		this.input.addEvent('change', function(){
			callback(this.get('value'));
		});
	},
	flatMap: function(obj, template)
	{
		var list_parse = function(obj, i){
			return typeof obj !== 'undefined' && obj.hasOwnProperty(i) ? obj[i] : '';
		};

		var template_keys       = template.match(/(?!\{)(\w+\.{0,}\w+)(?=\})/g);
		var template_substitute = {};

		template_keys.forEach(function(key){
			template_substitute[key] = key.split('.').reduce(list_parse, obj);
		});

		return template_substitute;
	},
	rebuild: function()
	{
		this.DropdownContainer.destroy();
		this.initialize(this.options);
	}
});
