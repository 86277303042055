window.addEvent('domready', function() {

	var mobile_form_filters = $$('.mobile_form_filters');

	mobile_form_filters.addEvent('change', function(){
		this.submit();
	});

	var DahsboardActivitiesViewSelect = $('DahsboardActivitiesViewSelect');
	if (DahsboardActivitiesViewSelect)
	{
		DahsboardActivitiesViewSelect.addEvent('change', function(){
			window.location = '/dashboard/' + DahsboardActivitiesViewSelect.get('value');
		});
	}

	var EditClient = $('EditClient');
	if (EditClient)
	{
		EditClient.addEvent('click', function(event){
			jQuery('#TabClientInfo a[href="#Project0"]').tab('show');
		});
	}

	var active_projects = $$('.active-project');

	active_projects.each(function(project, index){

		project_id = project.get('data-project-id');

		project.addEvent('click', function(event){
			event.stop();
			jQuery('#Tracing a[href="#Project' + project_id + '"]').tab('show');
		});

	});

	if (('standalone' in window.navigator) && window.navigator.standalone)
	{
		var noddy, remotes = false;

		document.addEventListener('click', function(event) {
			noddy = event.target;

			while (noddy.nodeName !== 'A' && noddy.nodeName !== 'HTML')
			{
				noddy = noddy.parentNode;
			}
			if ('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes))
			{
				event.preventDefault();
				document.location.href = noddy.href;
			}
		}, false);
	}
	$$('.input-switch').each(function(element){
		if (element)
		{
			new Element('label', {'for': element.get('id')}).inject(element, 'after');
			element.addClass('input-switch-enable');
		}
	});

	$$('.callto-call-to-action').each(function(callto_cta){
		callto_cta.addEvent('click', function(event){
			var phone_number = callto_cta.get('data-phone-number');
			var request = new Request({
				method: 'post',
				data: {
					'phone_number': phone_number,
					'client_id': CLIENT_ID,
				},
				url: URL_DOMAIN + '/client_notes/addcallrecord.json',
				onSuccess: function(response){
					window.location.reload();
				}
			}).send();
		});
	});
});
