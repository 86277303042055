var ActionLocationModal = new Class({
	data: null,

	initialize: function(visiting_time)
	{
		this.data = JSON.decode(visiting_time.get('data-locations'));

		visiting_time.addEvent('click', this.show_map_click.bind(this));
	},

	show_map_click: function(event)
	{
		var check_in_date = Date.parse(this.data.ActionCheckIn.created).format(Locale.get('Date.naturalDateTime'));
		var check_out_date = '—';
		var is_mobile = true;
		if (typeof this.data.ActionCheckIn.UserDevice != 'undefined')
		{
			is_mobile = this.data.ActionCheckIn.UserDevice.is_mobile;
		}
		if (this.data.ActionCheckOut)
		{
			check_out_date = Date.parse(this.data.ActionCheckOut.created).format(Locale.get('Date.naturalDateTime'));
		}

		var distance = this.data.distance != null ? Locale.get('Default.meters', this.data.distance) : '—';
		var visiting_time = this.data.time ? this.data.time : '—';

		var table_info = [
			'<table class="table-compact" style="width: 100%; margin-bottom: 1em;">',
				'<tbody>',
					'<tr>',
						'<td>',
							'<img src="/img/google-maps/marker-blue.png" alt="blue" height="15" /> ',
							'<strong>Check-In</strong>',
						'</td>',
						'<td>',
							'<img src="/img/google-maps/marker-red.png" alt="red" height="15" /> ',
							'<strong>Check-Out</strong>',
						'</td>',
						'<td><strong>' + Locale.get('Default.distance') + '</strong></td>',
						'<td><strong>' + Locale.get('Default.time') + '</strong></td>',
					'</tr>',
					'<tr>',
						'<td>' + check_in_date + '</td>',
						'<td>' + check_out_date + '</td>',
						'<td>' + distance + '</td>',
						'<td>' + visiting_time + '</td>',
					'</tr>',
				'</tbody>',
			'</table>',
		].join('');

		ActionLocationModal.modal().modal.getElement('.modal-body > .table-info').set('html', table_info);
		ActionLocationModal.modal().modal.getElement('.google-map').toggleClass('hidden', !is_mobile);
		ActionLocationModal.modal().show();

		if (is_mobile)
		{
			googleMapsInit(this.google_maps_ready.bind(this));
		}
	},

	google_maps_ready: function()
	{
		ActionLocationModal.update_google_map(this.data)
	}
});

ActionLocationModal.modal = function()
{
	if (this._modal)
	{
		return this._modal;
	}

	this._modal = new BootstrapModal({
		title: Locale.get('Default.locations'),
		size: 'lg',
		primaryButton: null,
		secondaryButton: {html: Locale.get('Default.close')},
	});
	this._modal.map = null;
	this._modal.markers = [];
	this._modal.modal.getElement('.modal-body').set('html', [
		'<div class="table-info"></div>',
		'<div class="google-map" style="width: 100%; height: 400px;"></div>',
	].join(''));

	return this._modal;
}

ActionLocationModal.markers = [];

ActionLocationModal.update_google_map = function(data)
{
	var modal = ActionLocationModal.modal();

	this.markers.forEach(function(marker){
		marker.setMap(null);
	});

	if (!modal.map)
	{
		modal.map = new google.maps.Map(modal.modal.getElement('.modal-body > .google-map'), {
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			mapTypeControl: false,
			disableDefaultUI: true
		});
	}

	var check_in = new google.maps.LatLng(data.ActionCheckIn.latitude, data.ActionCheckIn.longitude);

	this.markers = [
		new google.maps.Marker({icon: '/img/google-maps/marker-blue.png', map: modal.map, position: check_in})
	];

	if (data.ActionCheckOut)
	{
		var check_out = new google.maps.LatLng(data.ActionCheckOut.latitude, data.ActionCheckOut.longitude);

		this.markers.push(
			new google.maps.Marker({icon: '/img/google-maps/marker-red.png', map: modal.map, position: check_out})
		);
	}

	modal.map.setCenter(check_in);
	modal.map.setZoom(data.distance < 100 ? 17 : (data.distance < 1000 ? 15 : 12));

	google.maps.event.trigger(modal.map, 'resize');
}
