var SwitchSMTP = new Class({
	element: null,
	options: {
		smtp: null,
		sender: null,
		label: null
	},
	initialize: function(element, options)
	{
		this.element = element;
		this.options = options;

		if (!this.options.smtp)
			return;

		this.element.addEvent('change', this.switch_smtp.bind(this));
	},
	destroy: function()
	{
		this.element.removeEvents('change');
	},
	switch_smtp: function(event)
	{
		var checked = this.element.get('checked');

		if (this.options.sender)
		{
			var email = checked ? this.options.smtp.username : Auth.User.username;
			this.options.sender.set('value', email);

			if (email == Auth.User.username && !Auth.User.verified_email)
			{
				this.options.sender.getParent().adopt(new Element('a', {
					id: 'EmailNotVerifiedWarning',
					class: 'text-warning small',
					text: Locale.get('System.userEmailNotVerified'),
					href: '/profile/verify_user_email',
					target: '_blank'
				}));
			}
			else if (this.options.sender.getParent().getElement('#EmailNotVerifiedWarning'))
			{
				this.options.sender.getParent().getElement('#EmailNotVerifiedWarning').destroy();
			}
		}
		if (this.options.label)
		{
			this.options.label.set('text', checked ? this.options.smtp.host : 'Incrementa');
		}
	}
});
