if (String.prototype.includes === undefined)
{
	console.info('String.prototype.includes is undefined');

	String.prototype.includes = function(word){
		return this.toLowerCase().indexOf(word.toLowerCase()) > -1;
	}
}

if (Array.prototype.includes === undefined)
{
	console.info('Array.prototype.includes is undefined');

	Array.prototype.includes = function(search){
		var includes = false;

		this.forEach(function(item){
			if (item === search)
			{
				includes = true;

				return includes;
			}
		});

		return includes;
	}
}

if (window.requestAnimationFrame === undefined)
{
	console.info('requestAnimationFrame is undefined');

	window.requestAnimationFrame = function(callback){
		return setTimeout(callback, 1000 / 60);
	}
}
