// Access tables created on context so ajaxcomplete event can be watched and act upon updated table.
window.ajax_tables = [];

var TableAjax = new Class({
	table: {}, // elemento tabla a cargar con ajax
	pagination: {}, // lista de paginacion
	ajax_links: {}, // botones link de paginas u orders by
	url_page_button: document.URL.replace(window.location.protocol + '//' + document.domain, ''), // url actual
	limit_list: {},
	pagination_description: {}, // elemento que muestra.. 'Página 5 de 7, mostrando 10 registro(s) de 70'
	initialize: function(table) // constructor
	{
		this.table = table;
		this.initializeElements();
		this.tmp_elemnt = new Element('div');
		this.loader = new Element('span', {
			'class': 'loader-table-ajax pull-right loader loader-default'
		});
		this.span_arrow = new Element('span', {'class': 'arrow_sort'});
		this.table.addEvent('update', this.sendRequest.bind(this));
	},
	initializeElements: function() // inicia las propiedades de la clase con los elementos correspondientes
	{
		this.pagination             = document.getElement('.pagination');
		this.pagination_description = document.getElement('.pagination-description');
		this.limit_list             = document.getElement('.table-ajax-limit');
		this.ajax_links             = this.pagination ? this.pagination.getElements('a') : [];
		this.ajax_links.append(this.table.getElements('thead a'));

		if (this.limit_list)
		{
			this.limit_val = this.limit_list.get('value');
		}

		var self = this;
		if (self.ajax_links)
		{
			self.ajax_links.each(function(link){
				link.addEvent('click', function(event){
					event.stop();
					self.url_page_button = this.get('href');
					self.sendRequest();
				});
			});
		}
		if (self.limit_list)
		{
			self.limit_list.addEvent('change', function(event){

				if (event) event.stop()

				self.limit_val = this.get('value');
				self.url_page_button = self.url_page_button.replace(/\/limit:(\d+)*/g, '');
				self.url_page_button = self.url_page_button + '/limit:' + self.limit_val;
				self.sendRequest();
			});
		}
		self.table.tween('opacity', 1);
		jQuery('.enable-tooltip').tooltip();
	},
	sendRequest: function(url) // envia un get por ajax (si falla ajax get normal) para actualizar la tabla.
	{
		var self = this;
		if (url)
		{
			self.url_page_button = url
		}

		new Request({
			url: self.url_page_button,
			method: 'get',
			onRequest: function()
			{
				if (self.pagination_description)
				{
					self.pagination_description.adopt(self.loader);
					self.table.tween('opacity', 0.3);
				}
			},
			onSuccess: function(response)
			{
				self.tmp_elemnt.set('html', response);
				var table_response = self.tmp_elemnt.getElement('.table-ajax').dispose();
				if (table_response)
				{
					self.ajax_links.each(function(link){
						link.removeEvents('click');
					});

					if (self.limit_list)
						self.limit_list.removeEvents('change');

					if (table_response.getElement('thead'))
						table_response.getElement('thead').replaces(self.table.getElement('thead'));

					if (table_response.getElement('tbody'))
						table_response.getElement('tbody').replaces(self.table.getElement('tbody'));

					if (table_response.getElement('tfoot'))
						table_response.getElement('tfoot').replaces(self.table.getElement('tfoot'));

					var pagination_description = self.tmp_elemnt.getElement('.pagination-description');
					var pagination = self.tmp_elemnt.getElement('.pagination');

					if (pagination_description)
					{
						pagination_description_response = pagination_description.dispose();
						pagination_description_response.replaces(self.pagination_description);
					}
					if (pagination)
					{
						pagination_response = pagination.dispose();
						pagination_response.replaces(self.pagination);
					}

					var select_limit = document.getElement('.table-ajax-limit');
					if (select_limit)
						select_limit.set('value', self.limit_val);

					var sort_direction = self.url_page_button.match(/\/direction:(\w+)*/);
					var sort_field = self.url_page_button.match(/\/sort:([\w\.]+)*/);

					if (sort_direction && sort_field)
					{
						sort_direction = sort_direction[1];
						sort_field = sort_field[1];

						self.table.getElements('thead tr th a').each(function(sort_link){
							if (sort_link.get('href').search('/sort:' + sort_field) > -1)
							{
								self.span_arrow.set('html', sort_direction === 'desc' ? '↓' : '↑');
								sort_link.getParent().adopt(self.span_arrow).addClass('position-relative');
							}
						});
					}
					self.table.fireEvent('ajaxcomplete');
					self.initializeElements();
				}
				else
				{
					self.url_page_button = self.url_page_button.replace(/\/page:(\d+)*/g, '/page:1');
					window.location = self.url_page_button;
				}
			},
			onFailure: function()
			{
				self.table.fireEvent('ajaxcomplete');
				self.url_page_button = self.url_page_button.replace(/\/page:(\d+)*/g, '/page:1');
				window.location = self.url_page_button;
			}
		}).send();
	}
});

window.addEvent('domready', function() {

	$$('.table-ajax').each(function(table){
		var table_ajax = new TableAjax(table);

		if (table_ajax && document.getElement('.table-ajax-limit'))
		{
			var url_limit_paginator = table_ajax.url_page_button.match(/\/limit:(\d+)*/);
			if (url_limit_paginator)
			{
				url_limit_paginator = url_limit_paginator[1];
				document.getElement('.table-ajax-limit').set('value', url_limit_paginator);
				table_ajax.limit_val = url_limit_paginator;
			}
		}

		window.ajax_tables.push(table_ajax);
	});
});
