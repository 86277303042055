var TableView = new Class({
	Implements: Options,
	table: {},
	template: {},
	options: {
		url: '',
		page: 1,
		limit: 10,
		onRow: null,
		no_more_rows:{},
		next_page:{}
	},
	initialize: function(table, options)
	{
		this.table = table;
		this.setOptions(options);
		this.template = this.table.getElement('tbody tr:first-child').dispose();
	},
	setRow: function(row)
	{
		var template = this.template.clone();

		if (this.options.onRow)
			template = this.options.onRow(row, template);

		template.getElements('.data-field').each(function(item){
			var data_field = item.get('data-field').split('.');
			var model = data_field[0];
			var field = data_field[1];
			item.set('html', row[model][field]);
		});
		template.inject(this.table.getElement('tbody'));
	},
	next: function()
	{
		this.options.page++;
		this.getRows(this.options.page);
	},
	reset: function()
	{
		this.table.getElement('tbody').getElements('tr').destroy();
		this.options.next_page.removeClass('hide');
		this.options.no_more_rows.addClass('hide');
		this.options.page = 1;
		this.getRows(this.options.page);
	},
	getRows: function(page)
	{
		var self = this;
		new Request.JSON({
			method: 'post',
			url: this.options.url + '/page:' + page + '/limit:' + this.options.limit + '.json',
			onSuccess: function(rows)
			{
				rows.data.each(function(row) {
					self.setRow(row);
				});
				if (rows.data.length < self.options.limit)
				{
					self.options.next_page.addClass('hide');
					self.options.no_more_rows.removeClass('hide');
				}
			},
			onFailure: function(xhr)
			{
				self.options.next_page.addClass('hide');
				self.options.no_more_rows.removeClass('hide');
			}
		}).send();
	}
});
