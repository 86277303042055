window.addEvent('domready', function(){
	/* Modal: Nueva Oportunidad Global
	--------------------------------------------- */
	var initialize_create_project = function(CreateProject)
	{
		var ProjectTypeInput            = CreateProject.getElement('#ProjectProjectTypeInput');
		var ProjectClientId             = CreateProject.getElement('#ProjectClientId');
		var NewProjectActionDate        = CreateProject.getElement('#NewProjectActionDate');
		var ActionActionTypeId          = CreateProject.getElement('#ActionActionTypeId');
		var ProjectStatusActionId       = CreateProject.getElement('#ProjectStatusActionId');
		var ProjectNewUserAssignedInput = CreateProject.getElement('#ProjectNewUserAssignedInput');
		var OptionsButtonContainer      = CreateProject.getElements('.options-button-container');
		var OptionsButton               = CreateProject.getElements('.options-button');
		var Options                     = CreateProject.getElements('.options-row');
		var inputs                      = CreateProject.getElements('.form-control');
		var MoreOptionsCollapse         = CreateProject.getElements('#moreOptionsCollapse');
		var loader                      = new Loader(CreateProject);
		var today                       = new Date();
		var default_action              = '/projects/add/';

		if (typeof CLIENT !== 'undefined')
		{
			ProjectClientId.value = CLIENT.id;
		}

		var Selects = GlobalNewProjectModalContent.getElements('select');
		if (Selects)
		{
			Selects.each(function(select){
				var ds_select = new DynamicSearch({
					target: select,
					style: 'width: 100%',
					label: {
						empty: select.getProperty('placeholder') || ' — ',
						plural: select.getProperty('data-plural') || Locale.get('Label.elements'),
						singular: select.getProperty('data-singular') || Locale.get('Label.element'),
					},
					multiple: !!select.getProperty('multiple'),
					search: {
						enable: select.getElements('option').length > 10,
						min_chars: 1
					}
				});

				if (select.get('id') == 'ProjectStatusActionId')
				{
					select.addEvent('updated', function(event){
						ds_select.setOptions({
							search: {
								enable: select.getElements('option').length > 10,
								min_chars: 1
							}
						});

						ds_select.rebuild();
					})
				}
			});
		}

		var ClientSearch = new DynamicSearch({
			target: ProjectClientId,
			style: 'width: 100%',
			searchURL: '/clients/search.json',
			autoselectURL: '/clients/search/autoselect:true.json',
			templates: {
				value: '{Client.id}',
				label: [
					'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
					'<span class="label label-{Client.label}">{Client.type}</span></small>',
					'<span style="margin-right: 120px">{Client.name}</span>',
				].join(''),
				option: [
					'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
					'<span class="label label-{Client.label}">{Client.type}</span><br>{Client.phone}</small>',
					'<span style="margin-right: 120px">{Client.name}</span><br>',
					'<small class="text-muted" style="margin: 5px 0 120px 0; padding: 0;">{Client.email}</small>'
				].join(''),
			},
			label: {empty: Locale.get('Default.searchClientOrProspect')}
		});

		jQuery(MoreOptionsCollapse).on('shown.bs.collapse', function(){
			var triggerButton = jQuery(this).data('bs.collapse').$trigger[0];

			triggerButton.set('text', Locale.get('Label.lessOptions'));
		}).on('hidden.bs.collapse', function(){
			var triggerButton = jQuery(this).data('bs.collapse').$trigger[0];

			triggerButton.set('text', Locale.get('Label.moreOptions'));
		});

		if (ProjectTypeInput)
		{
			var custom_fields = CreateProject.getElements('.custom-fields');

			ProjectTypeInput.addEvent('change', function(event){
				var project_type_selected = (+this.value) || 0;

				custom_fields.each(function(field){
					var custom_field_project_type = (+field.getProperty('project-type')) || 0;

					field.toggleClass('hide', custom_field_project_type !== project_type_selected);
				});
			});
		}

		jQuery('#GlobalNewProject').modal('handleUpdate');
		inputs.removeProperty('required');

		var MobileView = $$('.clients.view.mobile')[0];
		var isAndroid = /android/i.test(navigator.userAgent.toLowerCase());
		if (!MobileView && !isAndroid)
		{
			CreateProject.getElements('.action_date_picker').each(function(input){
				new Picker.Date(input, {
					startDay: START_DAY,
					pickerClass: 'datepicker_incrementa',
					format: 'db',
					timePicker: true,
					useFadeInOut: false,
					draggable: false,
					minDate: today,
					onSelect: function(selected_date){
						if (!RETROACTIVE_SCHEDULING)
							return;

						var today = new Date();

						if (selected_date.toDateString() == today.toDateString())
						{
							var s_hours   = selected_date.getHours();
							var s_minutes = selected_date.getMinutes();
							var t_hours   = today.getHours();
							var t_minutes = today.getMinutes();

							if (s_hours <= t_hours && s_minutes <= t_minutes)
							{
								alert(Locale.get('Date.retroactiveTime'));
								this.input.set('value', null);
							}
						}
					}
				});
			});

			CreateProject.getElements('.input-date').each(function(input){
				new Picker.Date(input, {
					startDay: START_DAY,
					pickerClass: 'datepicker_incrementa',
					format: '%Y-%m-%d',
					timePicker: false,
					useFadeInOut: false,
					draggable: false
				});
			});
		}
		else if (isAndroid)
		{
			jQuery('#dtBox').DateTimePicker({
				animationDuration: 0,
				defaultDate: new Date(),
				dateTimeFormat: 'yyyy-MM-dd HH:mm',
				dateFormat: 'yyyy-MM-dd',
				shortDayNames: Locale.get('Date.days_abbr'),
				shortMonthNames: Locale.get('Date.months_abbr'),
				fullMonthNames: Locale.get('Date.months'),
				titleContentDate: '',
				titleContentDateTime: '',
				setButtonContent: Locale.get('Label.select'),
				clearButtonContent: Locale.get('Default.cancel')
			});
		}

		if (SIMULTANEOUS_ACT)
		{
			var check_simultaneous_activities = function()
			{
				var datetime = NewProjectActionDate.get('value');
				var user_id = ProjectNewUserAssignedInput.get('value');
				if (datetime === '')
				{
					return;
				}

				var OverlapActivities = CreateProject.getElement('#OverlapActivities');

				if (OverlapActivities)
				{
					OverlapActivities.destroy();
				}

				new Request.JSON({
					url: '/actions/simultaneous.json',
					data: {user_id: user_id, datetime: datetime},
					onSuccess: function(response)
					{
						if (response.response.error)
						{
							location.reload();
						}
						if (response.response.simultaneousActivities)
						{
							var count = response.response.count;

							var activities_list = new Element('ul', {class: 'list-group'});
							for (var activity in count)
							{
								var activities = +count[activity];

								if (activities)
								{
									var activity_item = new Element('li', {
										class: 'list-group-item text-' + activity,
										text: Locale.get('System.' + activity)
									});
									var badge = new Element('span', {class: 'badge text-danger', 'text': activities});

									badge.inject(activity_item);
									activity_item.inject(activities_list);
								}
							}

							CreateProject.getElement('.modal-body').adopt(new Element('div', {
								id: 'OverlapActivities',
								class: 'text-danger left',
								html: [
									'<label class="label label-danger">',
									Locale.get('System.simultaneousActivities'),
									'</label></br></br>'
								].join(''),
								style: 'width: 60%; margin-right: auto;'
							}).adopt(activities_list));
						}
					}
				}).send();
			}

			if (isAndroid)
			{
				jQuery('#NewProjectActionDate').on('change', check_simultaneous_activities);
			}

			if (NewProjectActionDate)
			{
				NewProjectActionDate.addEvent('change', check_simultaneous_activities);
			}

			if (ProjectNewUserAssignedInput)
			{
				ProjectNewUserAssignedInput.addEvent('change', check_simultaneous_activities);
			}

			if (ActionActionTypeId)
			{
				ActionActionTypeId.addEvent('change', check_simultaneous_activities);
			}
		}

		if (SCHEDULE_RESTRICTION)
		{
			var is_action_date_allowed = function()
			{
				var datetime = NewProjectActionDate.get('value');

				var InvalidTime = CreateProject.getElement('#InvalidTime');

				if (InvalidTime)
				{
					InvalidTime.destroy();
				}

				new Request.JSON({
					url: '/actions/in_allowed_timetable.json',
					data: {datetime: datetime},
					onSuccess: function(response)
					{
						if (response.response.error)
						{
							location.reload();
						}
						if (response.response.invalid_action_date)
						{
							CreateProject.getElement('.modal-body').adopt(new Element('p', {
								id: 'InvalidTime',
								class: 'text-danger right',
								html: Locale.get('System.timetableRestriction')
							}));

							CreateProject.getElement('input[type=submit]').setProperty('disabled', 'disabled');
						}
						else
						{
							CreateProject.getElement('input[type=submit]').removeProperty('disabled');
						}
					}
				}).send();
			}

			if (isAndroid)
			{
				jQuery('#NewProjectActionDate').on('change', is_action_date_allowed);
			}

			if (NewProjectActionDate)
			{
				NewProjectActionDate.addEvent('change', is_action_date_allowed);
			}
		}

		if (ProjectStatusActionId)
		{
			var request_status_actions = new Request.JSON({
				onSuccess: function(response)
				{
					update_status_actions(response.response.status_actions);
				}
			});

			var update_status_actions = function(status_actions)
			{
				var options = status_actions.map(function(status_action){
					return '<option value="' + status_action.value + '">' + status_action.name + '</option>';
				});

				ProjectStatusActionId.set('html', options.join(''));
				ProjectStatusActionId.fireEvent('updated');
			}

			if (ProjectTypeInput)
			{
				ProjectTypeInput.addEvent('change', function(){
					request_status_actions.send({
						url: '/projects/status_actions/project_type_id:' + this.get('value') + '.json'
					});
				});

				ProjectTypeInput.fireEvent('change');
			}
			else
			{
				request_status_actions.send({url: '/projects/status_actions/project_type_id:0.json'});
			}
		}

		CreateProject.getElements('input[data-cleave="currency"]').each(attachCleaveFunctionality);
		CreateProject.getElements('input[data-cleave="units"]').each(attachCleaveFunctionality);
		CreateProject.getElements('[data_advanced]').each(function(input, i){
			try
			{
				var advanced_options = JSON.decode(input.get('data_advanced'));
				new Cleave(input, advanced_options);
			}
			catch (e)
			{
			}

			input.set('data-advanced', null);
		});

		var controller_allowed = ['clients', 'prospects', 'projects'].indexOf(Auth.Request.controller) > -1;
		var view_allowed = ['view', 'add'].indexOf(Auth.Request.action) > -1;

		if (controller_allowed && view_allowed)
		{
			ClientSearch.parent.getParent('.form-group').addClass('hidden');
		}

		ClientSearch.onChange(function(client_id){
			CreateProject.set('action', default_action + client_id);
		});

		CreateProject.set('action', default_action + ClientSearch.selected());
		CreateProject.addEvent('submit', function(event){
			var ignore = [
				'data[Project][description]',
				'data[Project][projection]',
				'data[Project][units]',
				'data[Project][expected_close_date]',
				'data[Project][project_type_id]',
				'data[Project][status_action_id]',
				'data[Action][whatsapp_template_id]',
				'ClientSearch'
			];
			var stop_event = false;
			var stop_event_action_date = false;

			CreateProject.getElements('.form-group:not(.hide) .form-control').each(function(input){
				var input_required = !ignore.contains(input.get('name')) || input.hasClass('require');
				if (input_required && input.get('value').trim() === '')
				{
					stop_event = true;
					input.getParent().addClass('has-error');
					(function(){
						input.getParent().removeClass('has-error');
					}).delay(3000);
				}
				else if (input.get('data-type') === 'currency')
				{
					input.set('value', input.get('value').replace(/[^0-9$.]/g, ''));
				}
			});

			var date_time_regex = /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g;
			if (CreateProject.getElement('.action_date_picker'))
			{
				if (date_time_regex.test(CreateProject.getElement('.action_date_picker').get('value')))
				{
					stop_event_action_date = false;
				}
				else
				{
					stop_event_action_date = true;
					CreateProject.getElement('.action_date_picker').getParent().addClass('has-error');
					(function(){
						CreateProject.getElement('.action_date_picker').getParent().removeClass('has-error');
					}).delay(3000);
				}
			}

			if (stop_event || stop_event_action_date)
				event.stop();

			(function(){
				loader.stopAndReset();
			}).delay(3000);
		});

		Options.addClass('hide')

		OptionsButton.addEvent('click', function(event){
			event.stop();
			OptionsButtonContainer.slide('out');
			OptionsButtonContainer.getParent().tween('margin-bottom', 0);
			Options.removeClass('hide');
			(function(){
				jQuery('#GlobalNewProject').modal('handleUpdate');
			}).delay(550);
		});
	}

	var GlobalNewProjectModal = $('GlobalNewProject');
	var CreateProject = $('CreateProject');

	if (CreateProject)
	{
		initialize_create_project(CreateProject);
	}

	if (GlobalNewProjectModal)
	{
		var GlobalNewProjectModalContent = GlobalNewProjectModal.getElement('.modal-content');
		var default_content = GlobalNewProjectModalContent.get('html');

		jQuery(GlobalNewProjectModal).on('show.bs.modal', function(e) {
			var quick_opportunity  = e.relatedTarget.get('data-project-type');
			var request_url    = '/projects/quick_add';

			if (quick_opportunity)
			{
				request_url += '/quick_opportunity:true';
			}

			new Request({
				method: 'get',
				url: request_url,
				onSuccess: function(response)
				{
					window.requestAnimationFrame(function(){
						initialize_create_project(GlobalNewProjectModal.getElement('#CreateProject'));
					});
					GlobalNewProjectModalContent.set('html', response);

					var ActionTypeSelect = GlobalNewProjectModalContent.getElement('.action-type');
					var ProjectStatus = GlobalNewProjectModalContent.getElement('#ProjectStatusActionId');
					var WhatsappTemplates = GlobalNewProjectModalContent.getElement('.whatsapp-templates');
					if (WHATSAPP_TEMPLATES != 0)
					{
						ActionTypeSelect.addEvent('change', function(){
							if (this.get('value') == 5)
							{
								WhatsappTemplates.removeClass('hide');
								WhatsappTemplates.getElement('select').set('disabled', false);
							}
							else
							{
								WhatsappTemplates.addClass('hide');
								WhatsappTemplates.getElement('select').set('disabled', 'disabled');
							}
						});
					}

					if (ProjectStatus)
					{
						var ActionResult = $('ResultAction');
						var TerminalActionSlide = new Fx.Slide(ActionResult, {duration: 300}).hide();
						ProjectStatus.addEvent('change', function(element){
							var status_action = parseInt(this.get('value'));

							if (TERMINAL_STATUSES.includes(status_action))
							{
								TerminalActionSlide.slideIn();
							}
							else
							{
								TerminalActionSlide.slideOut();
							}
						});
					}
				}
			}).send();
		}).on('hide.bs.modal', function(e) {
			var CreateProject = $('CreateProject');

			if (CreateProject)
			{
				CreateProject.removeEvents('submit');
			}
		}).on('hidden.bs.modal', function(e) {
			GlobalNewProjectModalContent.getChildren().destroy();
			GlobalNewProjectModalContent.set('html', default_content);
		});
	}
});
