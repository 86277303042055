window.addEvent('domready', function(){

	var controller = Auth.Request.controller;
	var action = Auth.Request.action;
	var controllers_permitted = ['clients', 'prospects', 'projects'];
	var actions_permitted = ['view', 'edit', 'add'];

	if (controllers_permitted.indexOf(controller) == -1 && actions_permitted.indexOf(action) == -1)
		return;

	$$('[data-advanced]').each(function(input){
		var advanced_options = input.get('data-advanced');

		if (advanced_options && new Cleave(input, JSON.decode(advanced_options)))
		{
			input.set('data-advanced', null);
		}
	});
});
