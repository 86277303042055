window.addEvent('domready', function() {

	if ((Auth.Request.controller !== 'clients' && Auth.Request.controller !== 'prospects') || Auth.Request.action !== 'view')
		return;

	var LoadMails = $('LoadEmails');
	var NextPage = $('NextPage');
	if (LoadMails && NextPage)
	{
		var MailsTableView = new TableView($('ClientMails'), {
			url: '/' + Auth.Request.controller + '/mails/' + Auth.Request.pass[0],
			limit: 10,
			no_more_rows: $('NoMoreRows'),
			next_page: NextPage,
			onRow: function(row, template)
			{
				if (row.EmailMarketing.id > 0)
				{
					new Element('a', {
						'href': '/email_marketing/view/' + row.EmailMarketing.id,
						'html': row.EmailMarketing.name
					}).inject(template.getElement('.mail-origin'));
				}
				else
				{
					template.getElement('.mail-origin').set('html', Locale.get('Mail.sending')[row.Mail.origin]);
				}

				template.getElement('.mail-template').set('href', '/mail_contents/view/' + row.Mail.id);
				if (row.MailContent.subject)
				{
					template.getElement('.mail-template').set('html', row.MailContent.subject);
				}
				else if (row.MailTemplate.id > 0)
				{
					template.getElement('.mail-template').set('html', row.MailTemplate.name);
				}

				template.getElement('.mail-views').set('html', row.Mail.views).set('title', row.Mail.ip_viewer);
				template.getElement('.mail-clicks').set('html', row.Mail.clicks).set('title', row.Mail.ip_clicker);

				template.getElement('.mail-dispatched .glyphicon').addClass(row.Mail.sent ? 'glyphicon-check' : 'glyphicon-menu-close');
				if (row.Mail.error)
				{
					template.getElement('.tooltip-error').set('data-original-title', row.Mail.message);
				}
				if (row.Mail.sent_date)
				{
					var sent_date = (new Date(Date.parse(row.Mail.sent_date).setUserOffset())).naturalDateTime();
					template.getElement('.mail-sent-date').set('html', sent_date);
				}

				return template;
			}
		});
		var refresh_tooltips = function()
		{
			jQuery('.enable-tooltip').tooltip();
		}
		jQuery('#LoadEmails').on('shown.bs.tab', function(){
			MailsTableView.reset();
			setTimeout(refresh_tooltips, 1000);
		});
		NextPage.addEvent('click', function(){
			MailsTableView.next();
			setTimeout(refresh_tooltips, 1000);
		});
	}

	var LoadMailsMobile = $('LoadEmailsMobile');
	var NextPageMobile = $('NextPageMobile');
	if (LoadMailsMobile && NextPageMobile)
	{
		var MailsTableViewMobile = new TableView($('ClientMailsMobile'), {
			url: '/' + Auth.Request.controller + '/mails/' + Auth.Request.pass[0],
			limit: 10,
			no_more_rows: $('NoMoreRowsMobile'),
			next_page: NextPageMobile,
			onRow: function(row, template)
			{
				template.getElement('.mail-origin').set('html', Locale.get('Mail.sending')[row.Mail.origin]);

				if (row.EmailMarketing.id > 0)
				{
					new Element('a', {
						'href': '/email_marketing/view/' + row.EmailMarketing.id,
					}).inject(template.getElement('.mail-origin'));
				}

				if (row.MailTemplate.id > 0)
				{
					template.getElement('.mail-template').set('href', '/mail_templates/view/' + row.MailTemplate.id);
					template.getElement('.mail-template').set('html', row.MailTemplate.name);
				}
				else if (row.MailContent.subject)
				{
					template.getElement('.mail-template').set('html', row.MailContent.subject);
				}

				template.getElement('.mail-views').set('html', row.Mail.views).set('title', row.Mail.ip_viewer);

				var mail_status_class = (row.Mail.done && row.Mail.sent && !row.Mail.error) ? 'label-success' : 'label-warning';
				var status = 'sent';
				if (row.Mail.status === 'Error')
				{
					status = 'error';
					mail_status_class = 'label-danger';
				}

				template.getElement('.mail-status').addClass(mail_status_class).set('html', Locale.get('Mail.' + status));

				if (row.Mail.sent_date)
				{
					var sent_date = (new Date(Date.parse(row.Mail.sent_date).setUserOffset())).naturalDateTime();
					template.getElement('.mail-sent-date').set('html', sent_date);
				}

				return template;
			}
		});
		LoadMailsMobile.addEvent('click', function(){
			MailsTableViewMobile.reset();
		});
		NextPageMobile.addEvent('click', function(){
			MailsTableViewMobile.next();
		});
	}
});
