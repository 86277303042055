window.addEvent('domready', function(){
	var add_clipboard_helper = function(element)
	{
		if (!ClipboardJS.isSupported())
		{
			return element.addClass('hidden');
		}

		var clipboard = new ClipboardJS(element);

		clipboard.on('success', function(e){
			jQuery(e.trigger).on('shown.bs.tooltip', function(){
				setTimeout(function(){
					jQuery(this).tooltip('hide');
				}.bind(this), 600);
			});
		});
		clipboard.on('error', function(e){
			jQuery(e.trigger).on('shown.bs.tooltip', function(){
				setTimeout(function(){
					jQuery(this).tooltip('hide');
				}.bind(this), 600);
			});
		});
	}

	$$('.clipboard-actionable').each(add_clipboard_helper);
});
