// Check this class
var Stats = new Class({
	activities_today: 0,
	activities_scheduled: 0,
	activities_overdue: 0,
	max_per_day: 0,
	min_per_day: 0,
	efficiency: -1,
	punctuality: -1,
	projected: 0,
	sold: 0,
	projected_units: 0,
	sold_units: 0,
	quoted: 0,
	quoted_sold: 0,
	total_invoiced: 0,
	today_scheduled: 0,
	today_responses: 0,

	initialize: function(filters)
	{
		this.update(filters);
	},

	update: function(filters)
	{
		this.activities_today = 0;
		this.activities_scheduled = 0;
		this.activities_overdue = 0;
		this.max_per_day = 0;
		this.min_per_day = 0;
		this.projected = 0;
		this.sold = 0;
		this.projected_units = 0;
		this.sold_units = 0;
		this.quoted = 0;
		this.quoted_sold = 0;
		this.total_invoiced = 0;

		var today = new Date();
		var today_format = today.format('%Y-%m-%d');
		var total = 0, n = 0, limit = 0;

		for (var day in DashboardDensity)
		{
			if (!DashboardDensity.hasOwnProperty(day))
				continue;

			total = 0;
			var date = Date.parse(day).setUserOffset();
			for (n = 0, limit = DashboardDensity[day].length; n < limit; n++)
			{
				if (!filters.match(DashboardDensity[day][n]))
					continue;

				total += DashboardDensity[day][n].total;
			}

			if (day === today_format)
				this.activities_today = total;
			else if (date < today)
				this.activities_overdue += total;
			else if (date > today)
				this.activities_scheduled += total;

			if (this.max_per_day < total)
				this.max_per_day = total;

			if (this.min_per_day === 0 || this.min_per_day > total)
				this.min_per_day = total;
		}

		var efficiency = 0, punctuality = 0;

		for (total = 0, n = 0, limit = DashboardEvaluation.length; n < limit; n++)
		{
			if (!filters.match(DashboardEvaluation[n]))
				continue;

			total       += DashboardEvaluation[n].total;
			efficiency  += DashboardEvaluation[n].total_efficiency;
			punctuality += DashboardEvaluation[n].total_punctuality;
		}

		this.efficiency  = total > 0 ? efficiency / total * 100 : -1;
		this.punctuality = total > 0 ? punctuality / total * 100 : -1;
		this.total       = total;

		for (n = 0, limit = DashboardSales.length; n < limit; n++)
		{
			if (!filters.match(DashboardSales[n])) continue;

			this.projected       += DashboardSales[n].projected;
			this.sold            += DashboardSales[n].sold;
		}

		if (DashboardUnitStats && DashboardUnitStats.length !== 0)
		{
			for (n = 0, limit = DashboardUnitStats.length; n < limit; n++)
			{
				if (!filters.match(DashboardUnitStats[n])) continue;

				this.projected_units += DashboardUnitStats[n].projected_units;
				this.sold_units      += DashboardUnitStats[n].sold_units;
			}
		}

		this.today_scheduled = 0;
		this.today_responses = 0;

		for (n = 0, limit = DashboardProgress.length; n < limit; n++)
		{
			if (!filters.match(DashboardProgress[n]))
				continue;

			this.today_scheduled += DashboardProgress[n].total;
			this.today_responses += DashboardProgress[n].total_responses;
		}

		for (n = 0, limit = DashboardQuotes.length; n < limit; n++)
		{
			if (!filters.match(DashboardQuotes[n])) continue;

			this.quoted      += DashboardQuotes[n].quoted;
			this.quoted_sold += DashboardQuotes[n].quoted_sold;
		}

		if (window.DashboardInvoicing)
		{
			for (n = 0, limit = DashboardInvoicing.length; n < limit; n++)
			{
				if (!filters.match(DashboardInvoicing[n])) continue;

				this.total_invoiced += DashboardInvoicing[n].total_invoiced;
			}
		}

		return this;
	}
});
